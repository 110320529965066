import {
    call, put, select, all, take
} from 'redux-saga/effects';

import * as types from 'types/session';
import * as userTypes from 'types/user';
import {
    requestPatchUser,
} from 'actions/session';
import { addNotification } from 'actions/notification';
import {
    getCurrentUserIdSelector,
    getCurrentClientIdSelector,
} from 'selectors/user';
import { copyToClipboard } from 'utils/copyclipboard';
import { push } from 'redux-first-history';
import { getUrl } from 'utils/url';
import { getIsReadySelector } from 'selectors/session';
import Firebase from 'utils/firebase';
import config from 'config';
import Logger from 'services/debug/logger';
import { types as bcTypes, sagas, actions, config as backendConfig, utils, selectors } from '@amplement/backend-connector';
import { ROLES } from 'const/user';
import { getToken, decodeToken, setToken } from 'services/token';
import { getParentCompanyId } from 'selectors/company';

const logger = new Logger('saga:session');

function* guestLogin(action) {
    const { data } = action;

    if (data?.token) {
        logger.log('guestLogin:setAxiosToken: ', data.token);
        backendConfig.setConfig({ session: { isGuest: true } });
        yield call(sagas.session.loginSuccessLogic, {
            ...data,
            user: { ...(data?.user || {}), roles: [ROLES.GUEST] }
        });
    } else {
        yield put(addNotification({
            title: 'error.cannotAuthenticated',
            referenceError: 'GLOG1'
        }));
        yield put(actions.session.loginFail('An error has occured'));
    }
}

function* updateAndPatchUser(action) {
    const _user = yield select(getCurrentUserIdSelector);
    yield put(requestPatchUser(_user, { [action.key]: action.value }));
    const request = yield take(types.PATCH_USER_FAILURE);

    if (request.type === types.PATCH_USER_FAILURE) {
        yield put(addNotification({
            title: 'menuLeft.header.updateStatus.error',
            referenceError: 'USR3'
        }));
    }
}

function* notifyUpdateStatusSuccess(action) {
    if (action.payload.data && action.payload.data.status === undefined) {
        yield put(addNotification({ severity: 'success', title: 'profile.submit.success' }));
    }
}

function* notifyUpdateStatusError() {
    yield put(addNotification({
        title: 'profile.submit.failure',
        referenceError: 'USR3'
    }));
}

function* copyTextAndNotify({ text }) {
    copyToClipboard(text);

    yield put(addNotification({
        title: 'global.copiedToClipboard',
        severity: 'success'
    }));
}

function* updateStatus({ payload: { _user, silent } } = {}) {
    const _me = yield select(getCurrentUserIdSelector);

    if (_me === _user && !silent) {
        yield put(addNotification({ severity: 'success', title: 'profile.fields.status.submitSuccess' }));
    }
}

function* handleTabFocus({ isFocused }) {
    const isReady = yield select(getIsReadySelector);

    if (!isReady) {
        logger.log('handleTabFocus:notReady:skip', isReady);
        return ;
    }
    if (isFocused) {
        const isWsLaunchable = yield select(selectors.network.getIsWsCanLaunchSelector);

        if (isWsLaunchable) {
            logger.log('handleTabFocus:launchWebsocket');
            yield put(actions.websocket.launchWebsocket());
        }
    } 
}

function* setUserIdLogic({ user }) {
    if (user) {
        yield call(Firebase.setUserId, user.id);
        const clientId = yield select(getCurrentClientIdSelector);
        const _parent = yield select(getParentCompanyId);
        const properties = { companyId: user._company, env: config.env, clientId };
        // companyId, _parent, env and clientId should be a GA event instead of user property

        if (_parent) {
            properties._parent = _parent;
        }
        
        yield call(Firebase.setUserProperties, properties);
        yield call(utils.errorHandler.setUser, user);
    }
}

// eslint-disable-next-line
function* updateToken(action) {
    const cookieToken = getToken();
    const iatNextToken = decodeToken(action?.token)?.iat || 0;
    const iatCookieToken = decodeToken(cookieToken)?.iat || 0;

    if (iatNextToken > iatCookieToken) {
        setToken(action?.token);
    }

    yield call(sagas.session.setTokenLogic, { token: getToken() || action?.token });
}

function* requestLogout() {
    yield call(utils.errorHandler.setUser, undefined);
    yield put(push(getUrl('logout')));
}

function* postAvatarProxy({ avatar }) {
    yield put(actions.session.setPendingAvatar(avatar)); // because BC do it before saving
    yield put(actions.session.requestPostAvatar(avatar));
}

export default function* root() {
    yield all([
        utils.sagas.takeEvery(bcTypes.session.REQUEST_LOGIN, sagas.session.login),
        utils.sagas.takeEvery(bcTypes.session.REQUEST_LOGIN_SELECT, sagas.session.selectAccount),
        utils.sagas.takeEvery(types.REQUEST_GUEST_LOGIN, guestLogin),
        utils.sagas.takeLatest(types.POST_AVATAR, postAvatarProxy),
        utils.sagas.takeLatest(bcTypes.session.REQUEST_POST_AVATAR, sagas.session.postAvatar),
        utils.sagas.takeEvery(types.REQUEST_PATCH_USER, sagas.session.patchUser),
        utils.sagas.takeEvery(types.UPDATE_AND_PATCH_USER, updateAndPatchUser),
        utils.sagas.takeEvery(bcTypes.session.REQUEST_DELETE_USER, sagas.session.deleteUser),
        utils.sagas.takeEvery(types.PATCH_USER_SUCCESS, notifyUpdateStatusSuccess),
        utils.sagas.takeEvery(types.PATCH_USER_FAILURE, notifyUpdateStatusError),
        utils.sagas.takeEvery(userTypes.WS_PUT_STATUS, updateStatus),
        utils.sagas.takeEvery(types.COPY_TEXT, copyTextAndNotify),
        utils.sagas.takeLatest(types.SET_USER, setUserIdLogic),
        utils.sagas.takeEvery(bcTypes.session.REQUEST_LOGOUT, requestLogout),
        utils.sagas.takeEvery(types.SET_TAB_FOCUS, handleTabFocus),

        utils.sagas.takeLatest(bcTypes.session.UPDATE_TOKEN, updateToken),
        // utils.sagas.takeLatest(bcTypes.session.UPDATE_TOKEN, sagas.session.refreshTokenLogic),
        utils.sagas.takeLatest(bcTypes.session.REQUEST_REFRESH_TOKEN,  sagas.session.refreshTokenLogic),
        utils.sagas.takeLatest('@@router/LOCATION_CHANGE',  sagas.session.refreshTokenLogic),

        utils.sagas.takeEvery(bcTypes.session.FORGOT_PASSWORD_REQUEST, sagas.session.resetPassword),
        utils.sagas.takeLatest(bcTypes.session.SET_CLIENT, sagas.session.setClientIdLogic),
    ]);
}
