import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/* <FormattedMessage
id="foo"
defaultMessage="a changé le nom du groupe de <b><s>{oldName}</s></b> en <b>{newName}</b>"
values={{
  s: chunks => <s>{chunks}</s>,
  b: chunks => <b>{chunks}</b>,
  oldName: value,
  newName: newValue
}}
>
{chunks => <div>tutu{chunks}plop</div>} 
</FormattedMessage> */

const FeedNameChanged = ({ intl, value, newValue, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    return intl.formatMessage({ id: 'feedItem.feedNameChanged' }, {
        s: chunks => <s>{chunks}</s>,
        b: chunks => <b>{chunks}</b>,
        oldName: value,
        newName: newValue
    });
}

FeedNameChanged.propTypes = {
    intl: PropTypes.object.isRequired,
    onLoad: PropTypes.func,
    value: PropTypes.string.isRequired,
    newValue: PropTypes.string.isRequired,
};

FeedNameChanged.defaultProps = {
    onLoad: undefined,
};

export default memo(injectIntl(FeedNameChanged));
