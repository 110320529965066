export const WS_POST_FEED_ITEM = 'WS_POST_FEED_ITEM';
export const WS_DELETE_FEED_ITEM = 'WS_DELETE_FEED_ITEM';

export const GET_LAST_FEED_ITEMS = 'GET_LAST_FEED_ITEMS';

// SAGA
export const POST_FEED_ITEMS = 'POST_FEED_ITEMS';

export const POST_FEED_ITEM = 'POST_FEED_ITEM';
export const PUT_FEED_ITEM = 'PUT_FEED_ITEM';
export const DELETE_FEED_ITEM = 'DELETE_FEED_ITEM';
