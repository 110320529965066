import { utils } from '@amplement/backend-connector';
import merge from 'lodash/merge';
import deburr from 'lodash/deburr';
import memoize from 'lodash/memoize';

export const removeDiacritics = str => deburr(str);

export const getInitials = string => {
    if (!string) return '';
    if (/^[+#*0-9]*$/g.test(string)) {
        // it seems to be a phone number
        return '-';
    }
    const cleanString = removeDiacritics(string.trim()).toUpperCase().replace(/\W/g, ' ');
    const splitted = cleanString.split(' ').filter(e => e && e.trim().length);

    if (splitted.length >= 2) {
        return `${splitted[0][0]}${splitted[splitted.length - 1][0]}`;
    }
    return cleanString[0];
};

export const capitalize = memoize(str => {
    if (!str) return '';
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
});


export const getFullname = ({ firstname = undefined, lastname = undefined } = {}, args) => {
    if (!lastname && !firstname) return '';

    // handle opts
    const options = merge(
        {
            reverse: false,
            capitalize: true,
            upperCase: false,
            separator: ' ',
            lowerCase: false,
            initialize: false,
        },
        args,
    );
    const initializeFirstname = options.initialize === true || options.initialize === 'firstname';
    const lowerCaseFirstname =
        !initializeFirstname && (options.lowerCase === true || options.lowerCase === 'firstname');
    const upperCaseFirstname =
        !lowerCaseFirstname && (options.upperCase === true || options.upperCase === 'firstname');
    const capitalizeFirstname =
        !upperCaseFirstname &&
        !lowerCaseFirstname &&
        (options.capitalize === true || options.capitalize === 'firstname');
    let firstnameStyled = firstname || '';

    if (initializeFirstname) {
        firstnameStyled = getInitials(firstnameStyled);
    }
    if (lowerCaseFirstname) {
        firstnameStyled = firstnameStyled.toLowerCase();
    }
    if (upperCaseFirstname) {
        firstnameStyled = firstnameStyled.toUpperCase();
    }
    if (capitalizeFirstname) {
        firstnameStyled = capitalize(firstnameStyled);
    }

    const initializeLastname = options.initialize === true || options.initialize === 'lastname';
    const lowerCaseLastname =
        !initializeLastname && (options.lowerCase === true || options.lowerCase === 'lastname');
    const upperCaseLastname =
        !lowerCaseLastname && (options.upperCase === true || options.upperCase === 'lastname');
    const capitalizeLastname =
        !upperCaseLastname &&
        !lowerCaseLastname &&
        (options.capitalize === true || options.capitalize === 'lastname');
    let lastnameStyled = lastname || '';

    if (initializeLastname) {
        lastnameStyled = getInitials(lastnameStyled);
    }
    if (lowerCaseLastname) {
        lastnameStyled = lastnameStyled.toLowerCase();
    }
    if (upperCaseLastname) {
        lastnameStyled = lastnameStyled.toUpperCase();
    }
    if (capitalizeLastname) {
        lastnameStyled = capitalize(lastnameStyled);
    }

    if (!lastname) return firstnameStyled;
    if (!firstname) return lastnameStyled;
    if (options.initialize === true) return `${firstnameStyled}${lastnameStyled}`;
    if (options.reverse) return `${lastnameStyled}${options.separator}${firstnameStyled}`;

    return `${firstnameStyled}${options.separator}${lastnameStyled}`;
};


const memberEnrichment = (_room, _myUser, _myClient) => member => {
    const isMe = _myClient === member._client;

    const isJoined = utils.rooms.isJoinedOrHoldMember?.(member?.status) || undefined;

    const phonenumber = member.displayablePhonenumber || member.phonenumber || '';

    const fullname =
        phonenumber && !member.firstname && !member.lastname
            ? phonenumber
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            : getFullname(member);

    return {
        ...member,
        avatarUri: member.avatarUri,
        id: `${member._user}_${member._client}`,
        isJoined, // used only to sortBy isJoined, Should be removed after sort
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        firstname: getFullname({ firstname: member.firstname, lastname: undefined }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastname: getFullname({ firstname: member.lastname, lastname: undefined }),
        phonenumber, // due to mergeEntities, need to define a value to overwrite focusOn in redux
        title: phonenumber ? null : member.title || null,
        fullname,
        isMe,
        isMyUser: _myUser === member._user,
    };
};

const enrichMembers = (_room, _myUser, _myClient) => members =>
    members.map(member => memberEnrichment(_room, _myUser, _myClient)(member));

export default enrichMembers;
