import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
import { darken } from 'polished'; 

import { getUserByIdSelector } from 'selectors/user';

import Icon from 'components/Shared/Common/Icon';
import ContentTemplate from 'components/Shared/Feed/ItemTemplate/ContentTemplate';

const { Paragraph } = Typography;

const StyledReply = styled(Row)`
    width: 100%;
    background-color: ${props => props.theme.lighten};
    padding: 5px 10px;
    border-radius: 6px;
    position: relative;
    >.icon{
        display: flex;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin: 0 10px 0 6px;
        border-radius: 50%;
        background-color: ${props => darken(.1, props.theme.lighten)};
    }
    .ant{
        &-typography{
            margin-bottom: 0;
            line-height: 1.15;
        }
    }
    &:before{
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 4px;
        border-radius: 6px 0 0 6px;
        background-color: ${props => props.theme.mainColor2};
        top: 0;
    }
`;

const FeedItemQuote = ({ quote, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const user = useSelector(state => getUserByIdSelector(state, quote?._user));

    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    return (
        <StyledReply align="start" justify="start" wrap={false}>
            <Icon iconName="CornerUpRight" />
            <Col>
                <Paragraph strong>{user?.firstname} {user?.lastname}</Paragraph>
                <ContentTemplate content={quote.content} />
            </Col>
        </StyledReply>
    );
};

FeedItemQuote.propTypes = {
    quote: PropTypes.object,
    onLoad: PropTypes.func,
};

FeedItemQuote.defaultProps = {
    quote: {},
    onLoad: undefined
};

export default FeedItemQuote;
