import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Typography } from 'antd';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Button from 'components/Shared/Common/Button';
import Icon from 'components/Shared/Common/Icon';
import CallBuilder from 'components/CallBuilder';
import StyledCallButton from 'components/CallBuilder/DefaultResults/style';

import { StyledCard } from 'screens/Dashboard/styles';
import Link from 'components/Shared/Common/Link';

import useSipAccounts from 'hooks/useSipAccounts';
import useSipAccount from 'hooks/useSipAccount';

import { SIP_ACCOUNT_STATUS } from 'const/call';

import { colors } from 'common/themes/Colors';
import { getUrl } from 'utils/url';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const buttonRowStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '-40px',
    transform: 'translateX(-50%)',
    left: '50%',
};

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled(Title)`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    font-weight: 400 !important;
`;

const StyledCardContainer = styled(StyledCard)`
    margin-bottom: 10px;
`;

const CallbuilderCard = ({ intl }: WrappedComponentProps): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);
    const { lg, xl, xxl } = useBreakpoint();
    const { data: accounts } = useSipAccounts();
    const { id: _sipAccount } = accounts?.[0] || {};
    const { data: account } = useSipAccount({ _sipAccount });
    const { status } = account || {};

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const handleFocus = useCallback(() => {
        if (!isLargeScreen) {
            setIsVisible(true)
        }
    }, [isLargeScreen]);
    const handleBlur = useCallback(() => {
        if (!isLargeScreen) {
            setIsVisible(false)
        }
    }, [isLargeScreen]);

    const cardStyle = useMemo(() => ({ marginBottom: isVisible || !isLargeScreen ? 50 : 20 }), [isVisible]);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            <StyledDiv>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <StyledIcon bgColor={colors.black} iconName="Phone" />
                <StyledTitle level={4}>{intl.formatMessage({ id: 'call.createCall' })}</StyledTitle>
            </StyledDiv>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link
                href={getUrl('scheduler')}
                className="text"
            >
                <StyledCallButton>
                    <Button
                        iconName="Calendar"
                        marginRight={isLargeScreen ? '5px' : '0'}
                    >
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {isLargeScreen && <p>{intl.formatMessage({ id: 'menuLeft.navigation.scheduler' })}</p>}
                    </Button>
                </StyledCallButton>
            </Link>
        </StyledContainer>
    ), [intl, isLargeScreen]);

    return (
        <StyledCardContainer title={cardTitle}>
            <div style={cardStyle}>
                <CallBuilder
                    isSipDisconnected={status !== SIP_ACCOUNT_STATUS.REGISTERED}
                    autoFocus
                    showDirectCall
                    showLabel
                    useSip
                    buttonRowStyle={buttonRowStyle}
                    maxResultListHeight="60vh"
                    isListOpen={!isLargeScreen ? isVisible : undefined}
                    onClose={handleBlur}
                    onFocus={!isLargeScreen ? handleFocus : undefined}
                    onClickOutside={!isLargeScreen ? handleBlur : undefined}
                />
            </div>
        </StyledCardContainer>
    )
};

export default injectIntl(CallbuilderCard);
