import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as reactFeatherIcons from 'react-feather';
import MonitorOff from 'common/icons/monitor-off';
import PauseOff from 'common/icons/pause-off';
import Arrow from 'common/icons/arrow';
import BellOffCustom from 'common/icons/bell-off-custom';
import VolumeOff from 'common/icons/volume-off';
import ExclamationCircle from 'common/icons/exclamation-circle';
import Elipse from 'common/icons/phone-settings';
import Uniquity from 'common/icons/uniquity';
import Smile from 'common/icons/Smile';
import Hand from 'common/icons/hand';
import Pad from 'common/icons/pad';
import Shield from 'common/icons/shield';
import ToolBox from 'common/icons/tool-box';
import fileIcons from 'common/file/icons';
import Headphone from 'common/icons/headphone';
import HeadphoneOff from 'common/icons/headphoneOff';
import VideoMissed from 'common/icons/video-missed';
import VideoOutgoing from 'common/icons/video-outgoing';
import VideoIncoming from 'common/icons/video-incoming';
import HourlyCall from 'common/icons/hourly-call';
import NoAnswerCall from 'common/icons/no-answer-call';
import MinusCircleO from 'common/icons/minus-circle';
import InconditionnalCall from 'common/icons/inconditionnal-call';
import OccupedCall from 'common/icons/occuped-call';
import King from 'common/icons/king';
import Gpt from 'common/icons/gpt';
import PhoneOff from 'common/icons/phoneoff';
import Blur from 'common/icons/blur';

import buttonProps from 'components/Shared/Common/Button/Button.props';
import styled from 'styled-components';
// You can add icons in this Object, but you should check the name icon availability.
export const iconNames = {
    ...reactFeatherIcons,
    ...fileIcons,
    MonitorOff,
    PauseOff,
    VolumeOff,
    Arrow,
    BellOffCustom,
    Smile,
    Uniquity,
    Elipse,
    ExclamationCircle,
    Shield,
    ToolBox,
    PhoneOff,
    Hand,
    Headphone,
    HeadphoneOff,
    Pad,
    VideoMissed,
    VideoOutgoing,
    VideoIncoming,
    HourlyCall,
    NoAnswerCall,
    InconditionnalCall,
    MinusCircleO,
    OccupedCall,
    King,
    Gpt,
    Blur
};

const StyledIcon =  styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        display: inline-block;
    }
    &.xs svg{
        font-size: 10px;
    }
    &.sm svg{
        font-size: 12px;
    }
    &.md svg{
        font-size: 14px;
    }
    &.lg svg{
        font-size: 16px;
    }
    &.xl svg{
        font-size: 18px;
    }
`;

const UiIcon = ({ size, iconName, color, bgColor, className }) => {
    const Icon = iconNames[iconName];

    return Icon ? (
        <StyledIcon className={classNames('icon', className, size)}>
            <Icon color={bgColor} className={color} size="1em" />
        </StyledIcon>
    ) : null;
};

UiIcon.propTypes = {
    iconName: PropTypes.oneOf(Object.keys(iconNames)).isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    size: PropTypes.oneOf(buttonProps.sizes),
};

UiIcon.defaultProps = {
    className: undefined,
    color: undefined,
    bgColor: undefined,
    size: undefined
};

export default memo(UiIcon);
