import { useMutation, useQuery, UseQueryResult } from 'react-query';

import { apis } from '@amplement/backend-connector';
import { AxiosError } from 'axios';

export const REQUEST_LIMIT = 50;

type MetaPeer = unknown; // Remplacer par le type exact

interface FeedItemUser {
  firstname: string;
  id: string;
  lastname: string;
  title: string;
  _feed: string;
  _user: string;
  _metasPeers: MetaPeer[];
}

export interface FeedItem {
  _feed: string;
  _metasPeers: MetaPeer[];
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  isUnread?: boolean;
  content?: string;
  user?: FeedItemUser;
  metasFile?: MetaFile;
  _user: string;
}

interface MetaFile {
  extension: string;
  height: number;
  id: string;
  isDisplayable: boolean;
  mimeType: string;
  name: string;
  path: string;
  size: number;
  thumbnailHeight: number;
  thumbnailPath: string;
  thumbnailWidth: number;
  uri: string;
  width: number;
  updatedAt: string;
}

export type FeedType = FeedItem[];

interface CommonUseFeedItemsProps {
    _lastFeedItem: string;
    lastCreatedAt?: string;
}

type UsePreviousFeedItemsProps = {
    _feed: string;
    limit?: number;
} & CommonUseFeedItemsProps;

const usePreviousFeedItems = ({
    _feed,
    _lastFeedItem,
    lastCreatedAt,
    limit = REQUEST_LIMIT,
}: UsePreviousFeedItemsProps): UseQueryResult<FeedType> =>
    useQuery<FeedType>(
        ['feedItems', { _feed, _lastFeedItem, lastCreatedAt, limit }],
        async () => {
            const response = await apis.feedItems.$getPreviousFeedItems(_feed, _lastFeedItem, lastCreatedAt, limit);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
        }
    );

type UseGetFeedItemsProps = {
    onError?: (e: AxiosError<{message?: string}>) => void;
    onSuccess?: (data?: unknown) => void;
    _feed: string;
    limit?: number;
};

type UseGetFeedItemsResponseProps = {
    _feedItem: string;
    feedItemCreationDate?: string;
};

export const useGetNextFeedItems = ({
    onError,
    onSuccess,
    _feed,
    limit = REQUEST_LIMIT,
}: UseGetFeedItemsProps) => {
    const mutation = useMutation<{ data: FeedType }, AxiosError<{ message?: string }>, UseGetFeedItemsResponseProps>(
        ({ _feedItem, feedItemCreationDate }: UseGetFeedItemsResponseProps) => apis.feedItems.$getNextFeedItems(_feed, _feedItem, feedItemCreationDate, limit),
        {
            onSuccess: (data) => {
                onSuccess?.(data);
            },
            onError: (e: AxiosError<{ message?: string }>) => {
                onError?.(e);
            },
        }
    );

    return mutation;
}

export const useGetPreviousFeedItems = ({
    onError,
    onSuccess,
    _feed,
    limit = REQUEST_LIMIT,
}: UseGetFeedItemsProps) => {
    const mutation = useMutation<{ data: FeedType }, AxiosError<{ message?: string }>, UseGetFeedItemsResponseProps>(
        ({ _feedItem, feedItemCreationDate }: UseGetFeedItemsResponseProps) => apis.feedItems.$getPreviousFeedItems(_feed, _feedItem, feedItemCreationDate, limit),
        {
            onSuccess: (data) => {
                onSuccess?.(data);
            },
            onError: (e: AxiosError<{message?: string}>) => {
                onError?.(e);
            },
        }
    );

    return mutation;
}

export default usePreviousFeedItems;
