import Firebase from 'utils/firebase';
import { apiClient as request } from '@amplement/backend-connector';

export const $requestGuestLogin = (firstname, lastname, scope, _company) => {
    Firebase.logAnalyticsEvent('guest_login');
    return request.apiClient({
        url: '/auth/login/guest',
        method: 'POST',
        body: { firstname, lastname, scope, _company }
    });
}

export const $requestLogout = () => {
    Firebase.logAnalyticsEvent('user_logout');
    return request.apiClient({
        url: '/auth/logout',
        method: 'POST'
    });
};
