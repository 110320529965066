import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Card, Typography } from 'antd';
import styled from 'styled-components';

import { FeedItemResult, SearchResultsData } from 'hooks/useSearch';

import FeedItemMessageContent from 'components/Feed/FeedItem/FeedItemMessageContent';
import FeedItemMessageLayoutWithUser from 'components/Feed/FeedItem/FeedItemMessageLayoutWithUserAntd';
import MessageDetail from 'components/MessageList/MessageDetail';

const StyledCard = styled(Card)`
    margin-bottom: 10px;
    cursor: pointer;

    .ant-card-body {
        padding: 3px;

        .ant-comment {
            margin: 0;
        }
    }
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        flex: 1;
    }
`;

const StyledDiv = styled.div`
    max-height: 65vh;
    padding-right: 5px;
    overflow: auto;
`;

const { Text } = Typography;

type SearchItemProps = {
    item: FeedItemResult;
    handleSelectFeedItem: (item: FeedItemResult) => void;
    isSelected: boolean;
};

const SearchItem = ({ item, handleSelectFeedItem, isSelected }: SearchItemProps): JSX.Element => {
    const {
        _user,
        user,
        updatedAt,
        createdAt,
    } = item;

    const handleClick = useCallback(() => {
        handleSelectFeedItem(item);
    }, [handleSelectFeedItem, item]);

    return (
        <StyledCard onClick={handleClick}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <FeedItemMessageLayoutWithUser
                _user={_user}
                userFallback={user}
                createdAt={createdAt}
                hideFullname={false}
                updatedAt={updatedAt}
                isSelected={isSelected}
                hasAvatar
                showFullDate
            >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <FeedItemMessageContent item={item} />
            </FeedItemMessageLayoutWithUser>
        </StyledCard>
    );
}



const MessageList = ({ feedItems, intl }: SearchResultsData & WrappedComponentProps): JSX.Element => {
    const [selectedFeedItem, selectFeedItem] = useState<FeedItemResult | undefined>(undefined);
    const [mustScroll, setMustScroll] = useState(false);

    const handleSelectFeedItem = useCallback(feedItem => {
        setMustScroll(true);
        selectFeedItem(feedItem);
    }, []);

    useEffect(() => {
        selectFeedItem(undefined);
    }, [feedItems]);

    if (feedItems?.results?.length && 'content' in (feedItems?.results?.[0] ?? {})) {
        return (
            <StyledContainer>
                <StyledDiv>
                    {(feedItems?.results || []).map((item) => {
                        const { id } = item;

                        return (
                            <SearchItem
                                key={id}
                                item={item}
                                handleSelectFeedItem={handleSelectFeedItem}
                                isSelected={selectedFeedItem?.id === id}
                            />
                        );
                    })}
                </StyledDiv>

                {selectedFeedItem && (
                    <MessageDetail
                        key={selectedFeedItem?.id}
                        feedItem={selectedFeedItem}
                        mustScroll={mustScroll}
                        setMustScroll={setMustScroll}
                        selectFeedItem={selectFeedItem}
                    />
                )}
            </StyledContainer>
        );
    }

    return <Text>{intl.formatMessage({ id: 'callbuilder.emptyList' })}</Text>;
}

export default injectIntl(MessageList);
