import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography } from 'antd';

const { Text } = Typography;

const RecordingTemplate = ({ intl, specialType, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const translation = useMemo(() => {
        if (specialType === 'callStartRecording') {
            return intl.formatMessage({ id: 'feedItem.startRecording' });
        }

        if (specialType === 'callEndRecording') {
            return intl.formatMessage({ id: 'feedItem.endRecording' });
        }

        if (specialType === 'callPauseRecording') {
            return intl.formatMessage({ id: 'feedItem.pauseRecording' });
        }

        return '';
    }, [specialType, intl]);

    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    return (
        <Text type="secondary">
            {translation}
        </Text>
    );
};

RecordingTemplate.propTypes = {
    intl: PropTypes.object.isRequired,
    onLoad: PropTypes.func,
    specialType: PropTypes.string,
};

RecordingTemplate.defaultProps = {
    specialType: '',
    onLoad: undefined
};

export default memo(injectIntl(RecordingTemplate));
