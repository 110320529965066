import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import ContactCardList from 'components/Shared/Contacts/ContactCardList';
import useContacts from 'hooks/useContacts';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import { StyledRow, StyledContacts } from 'components/Shared/styles';

const ContactList = ({ 
    intl, 
    query, 
    contactStatus, 
    isInitiator, 
    sip,
    pageSize, 
    ItemComponent, 
    autoHide, 
    label, 
    onData, 
    displayMode, 
    noItemFallback,
    SkeletonComponent,
    hasShowMore,
    className
}) => {
    const res = useContacts(query, contactStatus, isInitiator, pageSize, sip);
    const { isLoading, error, isError, status, data, isFetching, hasNextPage, fetchNextPage} = res;
    let list = [];

    if (status === 'success') {
        data?.pages?.forEach(p => {
            list = [...list, ...p.data];
        });
    }

    useEffect(() => {
        if (status === 'success' && typeof onData === 'function') {
            onData(list);
        }
    }, [onData, status, list?.length])
    
    return (!autoHide || list?.length > 0) && (
        <StyledContacts className={className}>
            {label}
            <ContactCardList
                data={status === 'success' ? list : undefined}
                noData={list?.length === 0}
                isLoading={isLoading}
                error={isError && error.message}
                ItemComponent={ItemComponent}
                isFiltered={!!query}
                SkeletonComponent={SkeletonComponent}
                displayMode={displayMode}
                noItemFallback={noItemFallback}
            />
            {hasNextPage && hasShowMore && !!list?.length && (
                <StyledRow align="center" justify="center">
                    <div>
                        <Button 
                            disabled={isFetching} 
                            type="primary" 
                            block 
                            onClick={() => fetchNextPage()} 
                            style={{margin: '50px 0'}}
                        >
                            {intl.formatMessage({ id: 'global.button.showMore'})}
                        </Button>
                    </div>
                </StyledRow>
            )}
        </StyledContacts>
    );
};

ContactList.propTypes = {
    query: PropTypes.string,
    ItemComponent: PropTypes.any,
    hasShowMore: PropTypes.bool,
    SkeletonComponent: PropTypes.any,
    noItemFallback: PropTypes.any,
    sip: PropTypes.bool
};

ContactList.defaultProps = {
    query: undefined,
    ItemComponent: undefined,
    SkeletonComponent: undefined,
    noItemFallback: undefined,
    hasShowMore: true,
    sip: undefined
};

export default injectIntl(memo(ContactList, (prev, next) => prev.query === next.query && 
prev.contactStatus === next.contactStatus && 
prev.isInitiator === next.isInitiator && 
prev.noItemFallback === next.noItemFallback));
