import { useQuery, UseQueryResult } from 'react-query';
import { apis } from '@amplement/backend-connector';

export type Caller = {
    isMyUser?: boolean;
    number: string;
    localizedNumber: string;
    name: string;
    callback: string;
    external: boolean;
    hidden: boolean;
}

export type VoiceMailData = {
    id: string;
    timestamp: string;
    duration: number;
    datetime: string;
    caller: Caller;
    readState: boolean;
};

export interface SearchResultsData {
    data: VoiceMailData[];
}

interface useVoiceMailsProps {
    page?: number;
    limit?: number;
    voiceMailCount?: number;
}

const useVoiceMails = ({ limit, page, voiceMailCount }: useVoiceMailsProps): UseQueryResult<VoiceMailData[], unknown> =>
    useQuery(
        ['voiceMails', { limit, page, voiceMailCount }],
        async () => {
            const response = await apis.voiceMails.$getVoiceMails(limit, page);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
        }
    );

export default useVoiceMails;
