import { useQuery, UseQueryResult } from 'react-query';
import { apis } from '@amplement/backend-connector';

export type NotificationDataPayloadFeatures = {
    audio: boolean;
    dtmf: boolean;
    editMembers: boolean;
    feed: boolean;
    hold: boolean;
    mobility: boolean;
    persistence: boolean;
    public: boolean;
    recording: boolean;
    screenSharing: boolean;
    transferToPhonenumber: boolean;
    transferToRoom: boolean;
    transferToSipUser: boolean;
    transferToUser: boolean;
    transfers: string;
    video: boolean;
};

export type Member = {
    avatarUri: string | null;
    firstname: string | null;
    hasConnectivityIssues: boolean;
    invitedAt: string | null;
    isAdmin: boolean;
    isAudioEnabled: boolean;
    isCaller: boolean;
    isGuest: boolean;
    isSharingScreen: boolean;
    isVideoEnabled: boolean;
    joinedAt: string | null;
    lastname: string | null;
    phonenumber?: string;
    recordingState: number;
    status: number;
    title: string | null;
    _client: string | null;
    _user: string;
    displayablePhonenumber?: string;
};

export type Caller = {
    isMyUser?: boolean;
};

export type NotificationDataPayload = {
    features: NotificationDataPayloadFeatures;
    id: string;
    isHDToggleEnabled: boolean;
    isMulticast: boolean;
    isPersistent: boolean;
    isPrivate: boolean;
    isVideoCall: boolean;
    maxMembers: number;
    members: Member[];
    ringingDuration: number;
    startedAt: string;
    topology: string;
    updatedAt: string;
    _feed: string | null;
    _sourceFeed: string | null;
    name: string;
    caller?: Caller;
};

export type NotificationData = {
    category: string;
    createdAt: string;
    id: string;
    ignored: boolean;
    payload: NotificationDataPayload;
    silent: boolean;
    type: string;
    viewed: boolean;
    _entity: string;
};

export interface SearchResultsData {
    data: NotificationData[];
}

export interface UseNotificationsProps {
    category: string;
    type?: string;
    ignored?: boolean;
    read?: boolean;
    page?: number;
    limit?: number;
    notificationCount?: number;
}

const useNotifications = ({ category, type, limit, page, ignored, read, notificationCount }: UseNotificationsProps): UseQueryResult<NotificationData[], unknown> =>
    useQuery(
        ['notifications', { category, type, limit, page, ignored, read, notificationCount }],
        async () => {
            const response = await apis.notifications.$getNotifications(category, type, limit, page, ignored, read);
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            keepPreviousData: true,
        }
    );

export default useNotifications;
