import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Spin, Row, Col } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { LoadingOutlined, WarningTwoTone } from '@ant-design/icons';

import AvatarStatus from 'components/Shared/User/Avatar';
import Icon from 'components/Shared/Common/Icon';
import Tooltip from 'components/Shared/Common/Tooltip';
import FormattedDate from 'components/Shared/Common/FormattedDate';

import { getUserByIdSelector } from 'selectors/user';
import { getStatusById } from 'selectors/presence';

import { PRESENCE_STATUSES } from 'const/user';

import { StyledComment } from 'stories/RawScreens/components/RoomStyle';

import { FULL_DATE_FORMAT_CONFIG } from 'utils/date';
import { getUrl } from 'utils/url';

const DateTime = memo(injectIntl(({ intl, createdAt, isEdited }) => {
    const formatedTimes = useMemo(() =>
        intl?.formatDate ? ({
            span: intl.formatTime(createdAt),
            title: intl.formatDate(createdAt, FULL_DATE_FORMAT_CONFIG)
        }) : {}
    , [intl, createdAt]);

    return (
        <>
            <Tooltip
                title={formatedTimes.title}
                placement="topLeft"
            >
                <span className="meta-date">
                    {formatedTimes.span}
                </span>
            </Tooltip>
            {isEdited && (
                <span className="meta-edit">
                    <Icon iconName="Edit2" />
                </span>
            )}
        </>
    );
}));

const warningToneStyle = {
    fontSize: '24px',
    margin: 5
};

const StatusIndicator = memo(({ isSending, isFailed, failureMessage }) => {
    if (isSending) {
        return (
            <Col>
                <Spin indicator={<LoadingOutlined spin />} />
            </Col>
        );
    }

    if (isFailed) {
        return (
            <Col>
                <Tooltip title={failureMessage} placement="right">
                    <WarningTwoTone style={warningToneStyle} twoToneColor="#FF6600" />
                </Tooltip>
            </Col>
        );
    }

    return null;
});

const Content = memo(({ children, isSending, isFailed, failureMessage, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    if (!children) {
        return null;
    }

    return (
        <Row align="top" justify={isSending || isFailed ? 'end' : 'start'}>
            <Col flex={1}>{children}</Col>
            <StatusIndicator
                isSending={isSending}
                isFailed={isFailed}
                failureMessage={failureMessage}
            />
        </Row>
    );
});

export const FeedItemMessageLayoutWithUserComponent = forwardRef((props, ref) => {
    const {
        className,
        onDoubleClick,
        style,
        id,
        onLoad,
        hideFullname,
        showFullDate,
        updatedAt,
        status,
        _user,
        user: {
            firstname = '',
            lastname = '',
            avatarUri
        } = {},
        userFallback: {
            firstname: firstnameFallback = '',
            lastname: lastnameFallback = '',
            avatarUri: avatarUriFallback
        } = {},
        hasAvatar,
        hasStatus,
        isAvatarClickable,
        actions,
        isSelected,
        isEdited,
        isSending,
        isFailed,
        createdAt,
        children,
        failureMessage,
    } = props;
    const profileUrl = useMemo(() => _user ? getUrl('profile', { _user }) : undefined, [_user]);
    const memoizedClassname = useMemo(() => classNames(
        'message-item',
        className,
        {
            'no-avatar' : !hasAvatar,
            'editing': isSelected
        },
    ), [hasAvatar, className, isSelected]);
    const fullname = useMemo(() =>
        `${firstname || firstnameFallback} ${lastname || lastnameFallback}`,
    [
        firstname,
        lastname,
        firstnameFallback,
        lastnameFallback
    ]);

    return (
        <StyledComment
            ref={ref}
            id={id}
            style={style}
            className={memoizedClassname}
            author={!hideFullname ? <span>{fullname}</span> : undefined}
            actions={[actions]}
            onDoubleClick={onDoubleClick}
            avatar={hasAvatar ? (
                <AvatarStatus
                    src={avatarUri || avatarUriFallback}
                    size="md"
                    status={status}
                    hasStatus={hasStatus}
                    alt={fullname}
                    href={isAvatarClickable ? profileUrl : undefined}
                />
            ) : undefined}
            content={(
                <Content
                    onLoad={onLoad}
                    isSending={isSending}
                    isFailed={isFailed}
                    failureMessage={failureMessage}
                >
                    {children}
                </Content>
            )}
            datetime={showFullDate ? (
                <FormattedDate startDate={updatedAt} />
            ) : (
                <DateTime createdAt={createdAt} isEdited={isEdited} />
            )}
        />
    );
});

FeedItemMessageLayoutWithUserComponent.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    updatedAt: PropTypes.string,
    onDoubleClick: PropTypes.func,
    onLoad: PropTypes.func,
    style: PropTypes.object,
    hideFullname: PropTypes.bool,
    status: PropTypes.oneOf(Object.values(PRESENCE_STATUSES)),
    _user: PropTypes.string,
    user: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        avatarUri: PropTypes.string,
    }),
    userFallback: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        avatarUri: PropTypes.string,
    }),
    hasAvatar: PropTypes.bool,
    hasStatus: PropTypes.bool,
    actions: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.node
    ]),
    isEdited: PropTypes.bool,
    isSending: PropTypes.bool,
    isFailed: PropTypes.bool,
    showFullDate: PropTypes.bool,
    createdAt: PropTypes.any,
    children: PropTypes.any,
    isAvatarClickable: PropTypes.bool,
    failureMessage: PropTypes.string,
};

FeedItemMessageLayoutWithUserComponent.defaultProps = {
    className: '',
    onLoad: undefined,
    user: undefined,
    userFallback: undefined,
    id: undefined,
    updatedAt: undefined,
    onDoubleClick: undefined,
    status: undefined,
    style: undefined,
    hasAvatar: true,
    showFullDate: false,
    hasStatus: true,
    actions: undefined,
    children: undefined,
    createdAt: false,
    isEdited: true,
    hideFullname: true,
    isSending: false,
    isFailed: false,
    _user: undefined,
    isAvatarClickable: true,
    failureMessage: undefined,
};

const mapStateToProps = (state, { hideFullname, hasAvatar, _user }) => ({
    user: hasAvatar || !hideFullname ? getUserByIdSelector(state, _user) : undefined,
    status: getStatusById(state, _user)
});

export default connect(mapStateToProps, null, undefined, { forwardRef: true })(FeedItemMessageLayoutWithUserComponent);
