module.exports = {
    firebaseConfig: {
        apiKey: "AIzaSyDdg-4bnLbS4NnP6UuU2BLHQdU8HF_gRLs",
        authDomain: "amplement-ucaas.firebaseapp.com",
        projectId: "amplement-ucaas",
        storageBucket: "amplement-ucaas.appspot.com",
        messagingSenderId: "57869677648",
        appId: "1:57869677648:web:dcbbbd96fb993aaaa80d55",
        measurementId: "G-2LLJCXZ8BB"
    },
    feedItem: {
        relativeMaxDuration: 3 // days
    },
    feed: {
        reverseFeedItems: true
    },
    maxLastFileFeedItems: 4,
    room: {
        backendPingIntervalOnWsDown: 50000,
        displayEndedCallDuration: 0,
        displayRoomEndIfPersistent: true,
        displayRoomEndIfNoMembersInvited: true,
        autoUnHoldAtRoomEnd: false
    },
    features: {
        roomReminderEnabled: true,
        roomFastReplyEnabled: true,
    },
    defaultLanguage: 'en',
    fileParserHostname: 'https://file-parser-216107.appspot.com',
    browserUpdateLink: 'https://bestvpn.org/outdatedbrowser/',
    networkMonitoring: {
        reachability: {
            reachabilityUrls: []
        },
        wsRTTCountdownOnTabFocusOut: 5000,
    },
    sentryEnabled: true,
    requestLocalStreamAtWsRoomIncoming: false,
    webRTC: {
        maxPeersRequestedConcurrencies: 12,
        streamStatsInterval: 0, // asked by cedb -> too much flood
    },
    salesNumber: '+33 1.83.75.38.89',
    attemptApiDelays: [300, 800, 1200, 3000, 5000],
    activity: {
        pageSize: 20,
    },
    speakingMeterRefreshRate: 300,
    sentry: {
        timeout: true,
        wsEvent: false,
        networkError: true,
        tracesSampleRate: 0.0, // sentry performances
        replaysSessionSampleRate: 0.0, // sentry replay sample
        replaysOnErrorSampleRate: 1.0, // sentry replay on Error
    },
    webgl: {
        selfieSegmentationUrl: 'https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/',
    },
};
