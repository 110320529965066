import React, { useCallback } from 'react';
import { Space, Grid } from 'antd';

import Button from 'components/Shared/Common/Button';
import Separator from 'components/Shared/Layout/Separator';
import ToggleSound from 'components/Shared/Common/SoundButton/ToggleSound';

import { getUrl } from 'utils/url';

import DropDownActions from './DropDownActions';

const { useBreakpoint } = Grid;

type i18nProps = {
    startRoom: string;
    startRoomAudio: string;
    soundOff: string;
    soundOn: string;
    contactDrawerTooltip: string;
    galleryDrawerTooltip: string;
}

type CallButtonsProps = {
    isFeedUnicast: boolean;
    _feed: string;
    isMuted: boolean;
    onMute: () => void;
    isGalleryDrawerOpen: boolean;
    isContactDrawerOpen: boolean;
    name: string;
    i18n: i18nProps;
    isWebRtcCompatible: () => boolean;
    onOpenContactsDrawer: () => void;
    onOpenGalleryDrawer: () => void;
    closeDrawer: () => void;
};

const CallButtons = ({
    isFeedUnicast,
    _feed,
    isMuted,
    onMute,
    isGalleryDrawerOpen,
    isContactDrawerOpen,
    name,
    i18n,
    isWebRtcCompatible,
    onOpenContactsDrawer,
    onOpenGalleryDrawer,
    closeDrawer,
}: CallButtonsProps): JSX.Element => {
    const screens = useBreakpoint();
    const isCompact = screens?.xs || !screens?.lg;

    const audioRoomUrl = getUrl('roomByFeed', { _feed });
    const handleOpenContactsDrawer = useCallback(() =>
        isContactDrawerOpen
            ? closeDrawer()
            : onOpenContactsDrawer(), [isContactDrawerOpen, closeDrawer, onOpenContactsDrawer]);
    const handleOpenGalleryDrawer = useCallback(() =>
        isGalleryDrawerOpen
            ? closeDrawer()
            : onOpenGalleryDrawer(), [isGalleryDrawerOpen, closeDrawer, onOpenGalleryDrawer]);


    return isFeedUnicast ? (
        <Space>
            <Button
                className="icon-bell"
                color="primary"
                href={audioRoomUrl}
                target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                isTransparent
                tooltipText={i18n.startRoomAudio}
                tooltipPosition="bottom"
                isDisabled={!isWebRtcCompatible()}
            />

            <Separator />
            <Button
                iconName="Paperclip"
                isTransparent
                tooltipPosition="bottom"
                tooltipText={i18n.galleryDrawerTooltip}
                onClick={handleOpenGalleryDrawer}
                active={isGalleryDrawerOpen}
            />
        </Space>
    ) : (
        <Space>
            <ToggleSound
                enabled={!isMuted}
                onClick={onMute}
                iconClassName="black"
                buttonClassName="small"
                iconOff="BellOffCustom"
                iconOn="Bell"
                soundOff={i18n.soundOff}
                soundOn={i18n.soundOn}
            />
            <Separator />
            {isCompact ? (
                <Button
                    href={audioRoomUrl }
                    target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                    iconName={isWebRtcCompatible() ? 'Phone' : 'PhoneOff'}
                    isTransparent
                    tooltipText={i18n.startRoomAudio}
                    tooltipPosition="bottom"
                    isDisabled={!isWebRtcCompatible()}
                />)
                : (
                    <>
                        <Button
                            className="icon-bell"
                            color="primary"
                            href={audioRoomUrl}
                            target={_feed ? `roomByFeed-${_feed}` : 'blank'}
                            isDisabled={!isWebRtcCompatible()}
                        >
                            {i18n.startRoom}
                        </Button>
                        <Separator />
                        <Button
                            iconName="Users"
                            tooltipPosition="bottom"
                            tooltipText={i18n.contactDrawerTooltip}
                            isTransparent
                            onClick={handleOpenContactsDrawer}
                            active={isContactDrawerOpen}
                        />
                        <Button
                            iconName="Paperclip"
                            isTransparent
                            tooltipPosition="bottom"
                            tooltipText={i18n.galleryDrawerTooltip}
                            onClick={handleOpenGalleryDrawer}
                            active={isGalleryDrawerOpen}
                        />
                    </>
                )}
            <Separator />
            <DropDownActions
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                groupName={name}
                onClickContactsDrawer={handleOpenContactsDrawer}
                onClickGalleryDrawer={handleOpenGalleryDrawer}
                _feed={_feed}
                isCompact={isCompact}
            />
        </Space>
    );
}

export default CallButtons;