import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getFileSource } from 'utils/file';
import Button from 'components/Shared/Common/Button';
import FileImageTemplate from 'components/Shared/Feed/ItemTemplate/FileImageTemplate';
import FileGenericTemplate from 'components/Shared/Feed/ItemTemplate/FileGenericTemplate';
import FileAudioTemplate from 'components/Shared/Feed/ItemTemplate/FileAudioTemplate';
import emptyImageSrc from 'statics/images/placeholder-img-fallback.png';
import { isCallUrl } from 'utils/url';

class FileItem extends PureComponent {
    state = {
        isLoaded: false
    }

    componentDidMount() {
        const { onLoad } = this.props;

        if (!this.state.isLoaded) {
            this.setState({ isLoaded: true });
            onLoad?.();
        }
    }

    handleDownload = (e) => {
        const {
            src,
            name
        } = this.props;

        e.stopPropagation();
        e.preventDefault();
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = getFileSource(src, true);
        a.download = name;
        if (isCallUrl()) {
            a.target = "_blank";
        }
        a.click();
        a.remove();
        return false;
    }

    renderViewAction = () => {
        const { intl, onPreview } = this.props;

        return onPreview && (
            <Button
                onClick={onPreview}
                size="sm"
                color="secondary"
            >
                {intl.formatMessage({
                    id: 'feedItem.preview',
                    defaultMessage: 'Aperçu'
                })}
            </Button>
        );
    }

    render() {
        const {
            extension,
            isDisplayable,
            src,
            width,
            height,
            title,
            subtitle,
            thumb,
            isFailed,
        } = this.props;

        const isViewable = ['.mp4', '.mov', '.avi', '.ogg', '.webm', '.pdf'].includes(extension);
        const href = src ? getFileSource(src) : undefined;
        const tmb = thumb ? getFileSource(thumb) : undefined;
        const hrefDownload = (src || '').replace(/&token=.+$/, '');

        if (['.jpeg', '.png', '.jpg', '.gif', '.webp'].includes(extension)) {
            return (
                <FileImageTemplate
                    onDownload={this.handleDownload}
                    src={src ? href : emptyImageSrc}
                    thumb={tmb}
                    title={title}
                    width={width}
                    height={height}
                    subtitle={subtitle}
                    isGif={extension === '.gif'}
                />
            );

        }

        if (['.mp3', '.wav', '.flac', '.aac'].includes(extension)) {
            return <FileAudioTemplate src={href} /> // eslint-disable-line
        }

        return (
            <FileGenericTemplate
                extension={extension}
                isDisplayable={isDisplayable}
                title={title}
                subtitle={subtitle}
                onDownload={isFailed ? undefined : this.handleDownload}
                href={isFailed ? undefined : hrefDownload}
                actions={isViewable && this.renderViewAction()}
            />
        );
    }
}

FileItem.propTypes = {
    intl: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onLoad: PropTypes.func,
    extension: PropTypes.string.isRequired,
    isDisplayable: PropTypes.bool.isRequired,
    src: PropTypes.string,
    thumb: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isFailed: PropTypes.bool,
};

FileItem.defaultProps = {
    src: undefined,
    onLoad: undefined,
    thumb: undefined,
    width: undefined,
    height: undefined,
    title: undefined,
    subtitle: undefined,
    isFailed: false,
};

export default (FileItem);
