import styled from "styled-components";
import { Card, CardProps, Col } from "antd";

type StyledCardType = {
    maxWidth?: number;
    maxHeight?: number | string;
    children?: React.ReactNode;
} & CardProps;

export const StyledCol = styled(Col)`
    row-gap: 10px;
    width: 100%;
`;

export const StyledCard = styled(Card)<StyledCardType>`
    width: 100%;
    max-width: ${(props) => props.maxWidth}px;
    max-height: ${(props) => props.maxHeight}px;
    overflow: auto;
    border-radius: 12px;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    .ant-card-body {
        padding: 0 24px;
    }
`;

export const StyledEmptyCardWrapper = styled(StyledCard)`
    .ant-card-head {
        border-bottom: none;
    }
`;
