import React, { memo, useCallback, forwardRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import FeedItemMessageContent from 'components/Feed/FeedItem/FeedItemMessageContent';
import FeedItemMessageLayoutWithUser from 'components/Feed/FeedItem/FeedItemMessageLayoutWithUserAntd';
import Actions from 'components/Shared/Feed/ItemTemplate/FeedItemMessageTemplateActions';

const FeedItemMessageTemplate = forwardRef((props, ref) => {
    const [loadedCount, setLoadedCount] = useState(0);
    const {
        updateFeedItem,
        isSelected,
        style,
        isEditable,
        isFailed,
        onLoad,
        failureCode,
        _customFeedItem,
        failureMessage,
        isSending,
        hideAvatar,
        hideFullname,
        isAvatarClickable,
        hasStatus,
        item,
        item: {
            _user,
            user,
            specialType,
            content,
            id,
            createdAt,
            updatedAt,
            link,
            metasBot,
        },
    } = props;
    const isEdited = updatedAt !== createdAt && !link;
    const classes = useMemo(() => classNames({
        feedAction: specialType === 'feedNameChanged',
        joining: specialType === 'join',
        leaving: specialType === 'left',
        recording: specialType === 'callStartRecording',
        endRecording: specialType === 'callStopRecording' || specialType === 'callEndRecording',
    }), [specialType]);

    const enrichedStyle = useMemo(() => {
        if (isFailed) {
            return ({
                ...(style || {}),
                backgroundColor: '#f2aeaf',
            })
        }

        return style;
    }, [style, isFailed]);

    const handleEdit = useCallback(() => updateFeedItem?.(id, { content }), [id, content, updateFeedItem]);

    const handleLoadCount = useCallback(() => setLoadedCount((count) => count + 1), []);

    useEffect(() => {
        if (loadedCount > 1 && onLoad) {
            onLoad();
        }
    }, [onLoad, loadedCount]);

    return (
        <FeedItemMessageLayoutWithUser
            ref={ref}
            id={_customFeedItem}
            className={classes}
            onDoubleClick={(isEditable && !metasBot) ? handleEdit : undefined}
            style={enrichedStyle}
            hasAvatar={!hideAvatar}
            onLoad={handleLoadCount}
            actions={<Actions onEdit={handleEdit} {...props} />}
            _user={_user}
            userFallback={user}
            isSelected={isSelected}
            isEdited={isEdited}
            isSending={isSending}
            isFailed={isFailed}
            createdAt={createdAt}
            hideFullname={hideFullname}
            isAvatarClickable={isAvatarClickable}
            hasStatus={hasStatus}
            failureCode={failureCode}
            failureMessage={failureMessage}
        >
            <FeedItemMessageContent item={item} onLoad={handleLoadCount} />
        </FeedItemMessageLayoutWithUser>
    );
});

FeedItemMessageTemplate.propTypes = {
    style: PropTypes.object,
    updateFeedItem: PropTypes.func,
    deleteFeedItem: PropTypes.func,
    _customFeedItem: PropTypes.string,
    isSelected: PropTypes.bool,
    onLoad: PropTypes.func,
    item: PropTypes.object.isRequired,
    hideAvatar: PropTypes.bool,
    hideFullname: PropTypes.bool,
    isSending: PropTypes.bool,
    isFailed: PropTypes.bool,
    failureCode: PropTypes.number,
    failureMessage: PropTypes.string,
    isAvatarClickable: PropTypes.bool,
    hasStatus: PropTypes.bool,
};

FeedItemMessageTemplate.defaultProps = {
    style: undefined,
    updateFeedItem: null,
    _customFeedItem: undefined,
    deleteFeedItem: null,
    onLoad: null,
    isSelected: false,
    hideAvatar: true,
    hideFullname: true,
    isSending: false,
    isFailed: false,
    failureCode: undefined,
    failureMessage: undefined,
    isAvatarClickable: undefined,
    hasStatus: undefined,
};

export default memo(FeedItemMessageTemplate, isEqual);
