/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isEqual from 'react-fast-compare';
import { Row, Col, Popconfirm } from 'antd';

import Button from 'components/Shared/Common/Button';
import Tooltip from 'components/Shared/Common/Tooltip';
import { StyledInterfaceHeader } from 'components/Shared/style';

import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

import { isWebRtcCompatible } from 'utils/webRTC';

import FeedHeaderUnicastIdentity from './FeedHeaderUnicastIdentity';
import FeedHeaderMulticastIdentity from './FeedHeaderMulticastIdentity';
import FeedHeaderRoom from './FeedHeaderRoom';
import CallButtons from './CallButtons';

const formatMessages = intl => ({ name }) => ({
    contactDrawer: intl.formatMessage({
        id: 'feed.header.dropdown.contactDrawer',
        defaultMessage: 'Membres du groupe'
    }),
    startRoom: intl.formatMessage({
        id: 'feed.header.button.drawer.room',
        defaultMessage: 'Démarrer une conférence'
    }),
    startRoomAudio: intl.formatMessage({
        id: 'feed.header.button.drawer.room.audio',
        defaultMessage: 'Conférence audio'
    }),
    contactDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.users',
        defaultMessage: 'Membres'
    }),
    settingDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.settings',
        defaultMessage: 'Paramètres'
    }),
    galleryDrawerTooltip: intl.formatMessage({
        id: 'feed.header.button.drawer.gallery',
        defaultMessage: 'Documents'
    }),
    soundOff: intl.formatMessage({ id: 'menu.dropdown.sound.multicast.off' }, {
        b: chunks => <b>{chunks}</b>,
        groupName: name
    }),
    okConfirm: intl.formatMessage({ id: 'global.button.confirm' }),
    cancelConfirm: intl.formatMessage({ id: 'global.button.deny' }),
    close: intl.formatMessage({ id: 'global.button.close' }),
    joinRoom: intl.formatMessage({ id: 'room.joinRoom' }),
    joinRoomConfirmTitle: intl.formatMessage({ id: 'rtc.modal.global.video.title' }),
    redirectToFeed: intl.formatMessage({ id: 'feeds.redirectToFeed' }),
    soundOn: intl.formatMessage({ id: 'menu.dropdown.sound.multicast.on' }),
});

const FeedHeader = (props) =>  {
    const {
        _feed,
        isMuted,
        onMute,
        name,
        color,
        description,
        intl,
        isContactDrawerOpen,
        isGalleryDrawerOpen,
        user,
        isFeedUnicast,
        isLoading,
        _room,
        hideActions,
        closeDrawer,
        onOpenContactsDrawer,
        handleCloseChat,
        isRoomFeed,
        onOpenGalleryDrawer,
        deactivateLink,
        presence
    } = props;
    const i18n = formatMessages(intl)(props);

    useWhyDidYouUpdate('components:colleft:feed:header', props);

    const feedIdentity = useMemo(() => {
        if (isRoomFeed) {
            return <FeedHeaderRoom name={name} />;
        }

        if (isFeedUnicast) {
            return user && <FeedHeaderUnicastIdentity {...user} presence={presence} />;
        }

        return name && (
            <FeedHeaderMulticastIdentity
                name={name}
                color={color}
                description={description}
            />
        );
    }, [isRoomFeed, isFeedUnicast, user, name, color, description, presence]);

    const openRoom = useCallback(() => window.open(`/room/${_room}`, `room-${_room}`), [_room]);

    if (!_feed || isLoading) {
        return null;
    }

    return (
        <StyledInterfaceHeader className="interface-content-header">
            <Col span={24}>
                <Row justify="space-between" align="middle">
                    <Col flex="1">
                        <Row>
                            {feedIdentity}
                        </Row>
                    </Col>
                    {!hideActions && !isRoomFeed && (
                        <Col>
                            <div className="actions">
                                <CallButtons
                                    isFeedUnicast={isFeedUnicast}
                                    _feed={_feed}
                                    isMuted={isMuted}
                                    onMute={onMute}
                                    isGalleryDrawerOpen={isGalleryDrawerOpen}
                                    isContactDrawerOpen={isContactDrawerOpen}
                                    name={name}
                                    i18n={i18n}
                                    isWebRtcCompatible={isWebRtcCompatible}
                                    closeDrawer={closeDrawer}
                                    onOpenContactsDrawer={onOpenContactsDrawer}
                                    onOpenGalleryDrawer={onOpenGalleryDrawer}
                                />
                            </div>
                        </Col>
                    )}
                    {hideActions && !isRoomFeed && (
                        <>
                            <Col>
                                <Tooltip
                                    title={i18n.close}
                                    placement="top"
                                >
                                    <Button
                                        onClick={handleCloseChat}
                                        className="icon-bell"
                                        color="error"
                                        iconName="XCircle"
                                        isTransparent
                                    />
                                </Tooltip>
                            </Col>
                            {!deactivateLink && (
                                <Col>
                                    <Tooltip
                                        title={i18n.redirectToFeed}
                                        placement="top"
                                    >
                                        <Button
                                            className="icon-bell"
                                            color="primary"
                                            href={`/feed/${_feed}`}
                                            iconName="ArrowRight"
                                            isTransparent
                                            tooltipPosition="bottom"
                                        />
                                    </Tooltip>
                                </Col>
                            )}
                        </>
                    )}
                    {hideActions && isRoomFeed && (
                        <>
                            <Col>
                                <Tooltip
                                    title={i18n.close}
                                    placement="top"
                                >
                                    <Button
                                        onClick={handleCloseChat}
                                        className="icon-bell"
                                        color="error"
                                        iconName="XCircle"
                                        isTransparent
                                    />
                                </Tooltip>
                            </Col>
                            {!deactivateLink && (
                                <Col>
                                    <Popconfirm
                                        placement="bottom"
                                        title={i18n.joinRoomConfirmTitle}
                                        onConfirm={openRoom}
                                        okText={i18n.okConfirm}
                                        cancelText={i18n.cancelConfirm}
                                    >
                                        <Tooltip
                                            title={i18n.joinRoom}
                                            placement="top"
                                        >
                                            <Button
                                                className="icon-bell"
                                                color="primary"
                                                iconName="ArrowRight"
                                                isTransparent
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Col>
        </StyledInterfaceHeader>
    );
}

FeedHeader.propTypes = {
    isFeedUnicast: PropTypes.bool,
    isMuted: PropTypes.bool,
    onMute: PropTypes.func,
    _room: PropTypes.string,
    handleCloseChat: PropTypes.func,
    hideActions: PropTypes.bool,
    onOpenContactsDrawer: PropTypes.func.isRequired,
    onOpenGalleryDrawer: PropTypes.func.isRequired,
    isRoomFeed: PropTypes.bool,
    feed: PropTypes.object,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    isContactDrawerOpen: PropTypes.bool,
    isGalleryDrawerOpen: PropTypes.bool,
    presence: PropTypes.any,
    closeDrawer: PropTypes.func.isRequired
};

FeedHeader.defaultProps = {
    isFeedUnicast: null,
    isMuted: false,
    handleCloseChat: () => {},
    _room: null,
    isRoomFeed: false,
    hideActions: false,
    onMute: () => {},
    feed: null,
    user: null,
    isContactDrawerOpen: false,
    isGalleryDrawerOpen: false,
    presence: undefined
};

export default injectIntl(memo(FeedHeader, isEqual));
