import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

import SchedulerWrapper from 'components/Shared/Forms/Scheduler/SchedulerWrapper';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';

import { getUrl } from 'utils/url';

import { StyledPageLayout } from 'components/Shared/style';

const { Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
    max-width: 650px;
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: -20px;
`;

const SchedulerPage = ({ intl }: WrappedComponentProps): JSX.Element => (
    <StyledPageLayout className="ui-contact">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Link href={getUrl('dashboard')}>
            <StyledButton type="link">
                <Icon size="xl" iconName="ChevronLeft" />
                {intl.formatMessage({ id: 'menuLeft.navigation.goBackToDashboard' })}
            </StyledButton>
        </Link>
        <CategoryTitle
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={intl.formatMessage<string>({ id: 'room.scheduleForm.title' })}
            icon={<Icon size="xl" iconName="Calendar" />}
        />
        <StyledParagraph>{intl.formatMessage({ id: 'room.scheduleForm.description' })}</StyledParagraph>
        <SchedulerWrapper isFakeListDropdown />
    </StyledPageLayout>
);

export default injectIntl(SchedulerPage);
