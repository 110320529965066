import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import ContentTemplate from 'components/Shared/Feed/ItemTemplate/ContentTemplate';

import toArray from 'lodash/toArray';
// import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const FeedItemContentLayout = (props) => {
    const { content, children, isChildrenBeforeContent, onLoad } = props;

    const className = useMemo(() => {
        if (content) {
            const contentWithoutSpace = content.replace(/\s/gim, '');
            const isBigEmoji = contentWithoutSpace && contentWithoutSpace.length >= toArray(contentWithoutSpace).length * 2;
            return `content${isBigEmoji ? ' bigemoji' : ''}`;
        }

        return 'content';
    }, [content]);
    // useWhyDidYouUpdate('components:feed:feeditems:content:layout', props);

    return (
        <>
            {isChildrenBeforeContent && children}
            <div className={className}>
                {content && <ContentTemplate onLoad={onLoad} content={content} />}
                {!isChildrenBeforeContent && children}
            </div>
        </>
    );
};

FeedItemContentLayout.propTypes = {
    content: PropTypes.string,
    children: PropTypes.any,
    isChildrenBeforeContent: PropTypes.bool,
    onLoad: PropTypes.func
};

FeedItemContentLayout.defaultProps = {
    content: null,
    children: null,
    isChildrenBeforeContent: false,
    onLoad: null
};

export default injectIntl(FeedItemContentLayout);
