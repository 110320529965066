import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';

import UnsupportedBrowserComponent from 'components/LeftMenu/Rooms/UnsupportedBrowser';
import { isWebRtcCompatible } from 'utils/webRTC';

import { NOTIFICATION_CATEGORIES } from 'const/notification';
import { connect } from 'react-redux';
import { selectors, apis } from '@amplement/backend-connector';
import Icon from 'components/Shared/Common/Icon';
import { Badge } from 'antd';
import { injectIntl } from 'react-intl';

import CallHistory from 'components/Shared/Drawers/Sip/CallHistory/container';
import VoiceMails from 'components/Shared/Drawers/Sip/VoiceMails/container';
import ForwardsWrapper from 'components/Shared/Drawers/Sip/Forwards';
import CallConfigurationForm from 'components/Settings/CallConfigurationForm';

import styled from 'styled-components';
import { StyledTabs } from 'components/Shared/styles';
import { getCompanyTabByNameSelector } from 'selectors/company';
import { getUrl } from 'utils/url';

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-badge{
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .icon{
        display: flex;
        align-items: center;
        margin-right: 5px;
        svg{
            width: 14px;
            height: 14px;
        }
    }
`;

const CallTabs = memo(injectIntl(({
    intl,
    notificationCount,
    unReadVoiceMailCount,
    isVoiceMailSupported,
    isEnabled,
    isEditable,
    _account,
    history
}) => {
    const [activeTab, setActiveTab] = useState(NOTIFICATION_CATEGORIES.ROOM);

    const handleTabChange = useCallback((activeKey) => {
        setActiveTab(activeKey);
        history.push(getUrl(activeKey));
    }, [setActiveTab, history]);

    useEffect(() => {
        const { pathname } = history.location;
        if (pathname?.indexOf(getUrl('voicemail')) !== -1) {
            setActiveTab('voicemail');
        } else if (pathname?.indexOf(getUrl('forwards')) !== -1) {
            setActiveTab('forwards');
        } else if (pathname?.indexOf(getUrl('deviceConfiguration')) !== -1) {
            setActiveTab('deviceConfiguration');
        } else if (pathname?.indexOf(getUrl('call')) !== -1) {
            setActiveTab('call');
        }
    }, [history.location.pathname]);

    if (!isWebRtcCompatible()) {
        return <UnsupportedBrowserComponent />;
    }

    const items = useMemo(() => [{        
        key: 'call',
        label: (
            <StyledLabel>
                <Icon iconName="BookOpen" />
                <Badge overflowCount={999} count={notificationCount} offset={[8, 0]}>
                    {intl.formatMessage({ id: 'call.tabs.callHistory' })}
                </Badge>
            </StyledLabel>
        ),
        children: <CallHistory category={NOTIFICATION_CATEGORIES.ROOM} />
    }, {
        key: 'voicemail',
        label: (
            <StyledLabel>
                <Icon iconName="Voicemail" />
                <Badge overflowCount={999} count={unReadVoiceMailCount} offset={[8, 0]}>
                    {intl.formatMessage({ id: 'call.tabs.voiceMail' })}
                </Badge>
            </StyledLabel>
        ),
        children: <VoiceMails />,
        disabled: !!(!isEnabled || !isVoiceMailSupported)
    }, {
        key: 'forwards',
        label: (
            <StyledLabel>
                <Icon iconName="PhoneForwarded" />
                {intl.formatMessage({ id: 'call.tabs.forwardSettings' })}  
            </StyledLabel>
        ),
        children: <ForwardsWrapper />,
        active: !!(_account && isEnabled && isEditable && !!apis?.callForward?.$getAllForwardsSettings)
    }, {
        key: 'deviceConfiguration',
        label: (
            <StyledLabel>
                <Icon iconName="Phone" />
                {intl.formatMessage({ id: 'settings.menu.callConfiguration' })}
            </StyledLabel>
        ),
        children: <CallConfigurationForm />,
    }].filter(x => x.active !== false)
    , [isEnabled, isVoiceMailSupported, unReadVoiceMailCount, _account, isEditable]);

    return (
        <StyledTabs
            activeKey={activeTab}
            onChange={handleTabChange}
            destroyInactiveTabPane
            items={items}
        />
    );
}));

const mapStateToProps = state => {
    const { status, id: _account } = selectors.sip.getSIPAccount(state) || {};
    const { options: { isEnabled = true, isEditable = true } = {}} = getCompanyTabByNameSelector(state, 'phone') || {};

    return ({
        _account,
        status,
        notificationCount: selectors.notifications.getUnreadNotificationCountByCategorySelector(state, NOTIFICATION_CATEGORIES.ROOM),
        unReadVoiceMailCount: selectors.voiceMails.unreadVoiceMailCountSelector(state),
        isVoiceMailSupported: selectors.voiceMails.voiceMailSupportedSelector(state),
        isEnabled,
        isEditable,
    });
};

const CallTabsContainer = connect(mapStateToProps, null)(CallTabs);

export default memo(injectIntl(CallTabsContainer));
