import { matchPath } from "react-router";

export const getDomain = (url?: string | null | undefined) : string | undefined => {

    if (!url) return;
    let domain;

    try {

        const hostName = new URL(url).hostname;
        domain = hostName;

        if (hostName) {

            const reversedParts = hostName.split('.').reverse();

            if (reversedParts && reversedParts.length) {

                domain = `${reversedParts[1]}.${reversedParts[0]}`;

                if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && reversedParts.length > 2) {

                    domain = `${reversedParts[2]}.${domain}`;

                }

            }

        }

    } catch (e) {

        domain = url;

    }

    return domain; // eslint-disable-line

};

type MycRoute = {
    name: string;
    url: string;
    allowGuest?: boolean;
    allowAnonymous?: boolean
}

const routes: MycRoute[] = [
    { name: 'feed', url: '/feed/:_feed' },
    { name: 'feedByUser', url: '/feed/user/:_user' },
    { name: 'room', url: '/room/:_room', allowGuest: true},
    { name: 'call', url: '/call' },
    { name: 'roomByUser', url: '/room/user/:_user' },
    { name: 'roomInvite', url: '/room/:_room/invite', allowGuest: true, allowAnonymous: true },
    { name: 'roomByFeed', url: '/room/feed/:_feed' },
    { name: 'roomByNumber', url: '/room/number/:destNumber' },
    { name: 'roomCreate', url: '/room/create' },
    { name: 'profileEdition', url: '/profile/edit' },
    { name: 'profile', url: '/profile/:_user' },
    { name: 'ownProfile', url: '/profile' },
    { name: 'voicemail', url: '/call/voicemail/' },
    { name: 'forwards', url: '/call/forwards/' },
    { name: 'deviceConfiguration', url: '/call/devices/' },
    { name: 'configuration', url: '/call/configurations/' },
    { name: 'internalContacts', url: '/contacts/' },
    { name: 'businessContacts', url: '/contacts/business' },
    { name: 'activities', url: '/activities/' },
    { name: 'dashboard', url: '/dashboard/' },
    { name: 'settings', url: '/settings/' },
    { name: 'selfcare', url: '/custom/selfcare/' },
    { name: '404', url: '/404', allowGuest: true, allowAnonymous: true },
    { name: 'loginGuest', url: '/login-guest', allowAnonymous: true },
    { name: 'logout', url: '/logout', allowGuest: true, allowAnonymous: true },
    { name: 'version', url: '/version', allowGuest: true, allowAnonymous: true },
    { name: 'secret', url: '/secret' },
    { name: 'health', url: '/health', allowGuest: true },
    { name: 'admin', url: '/admin' },
    { name: 'login', url: '/login', allowGuest: true, allowAnonymous: true },
    { name: 'home', url: '/' },
    { name: 'faq', url: '/:lang/faq', allowGuest: true, allowAnonymous: true },
    { name: 'search', url: '/search' },
    { name: 'searchFeeds', url: '/search/feeds' },
    { name: 'searchFiles', url: '/search/files' },
    { name: 'scheduler', url: '/scheduler' },
    // { name: 'login', url: '/login', allowGuest: true, allowAnonymous: true },
    { name: 'whitelabelHome', url: '/:lang/', allowGuest: true, allowAnonymous: true }
];

const getNormalizedPath = (url: string = ''): string => url?.replace(/\/$/, '')?.replace(/[?].*$/, '');

export const compareUrl = (url1: string = '', url2: string = ''): boolean => getNormalizedPath(url1) === getNormalizedPath(url2);

// /!\ not manage anchor yet
export const createUrl = (url: string, params: string | { [key: string]: string }): string => {
    let buildUrl = url ?? '';
    const separator = buildUrl.indexOf('?') === -1 ? '?' : '&';

    if (!params) {
        return buildUrl;
    }

    if (typeof params === 'string') {
        buildUrl = `${buildUrl}${params ? separator : ''}${params}`
    } else if (typeof params === 'object') {
        const qs = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key] || '')}`).join('&');
        buildUrl = `${buildUrl}${qs ? separator : ''}${qs}`;
    }

    return buildUrl;
}

export const getUrl = (routeName: string, params: { [key: string]: string | null | undefined } = {}): string | undefined => {
    const route: MycRoute | undefined = routes.find(x => x.name === routeName);
    const paramsNotInRoute: string[] = [];
    let url: string | undefined;

    if (route) {
        url = route.url; // eslint-disable-line
        Object.keys(params).forEach((key) => {
            const re = new RegExp(`:${key}`, 'gim');
            const replacedUrl = url?.replace(re, encodeURIComponent(params[key] || ''));
            if (url === replacedUrl) {
                paramsNotInRoute.push(key);
            }
            url = replacedUrl;
        });

        if (paramsNotInRoute.length) {
            const qs = paramsNotInRoute.map(key => `${key}=${encodeURIComponent(params[key] || '')}`).join('&');
            url = createUrl(url, qs);
        }
    }
    return url;
};

export const isGuestAllowedUrl = (url: string, props = {}): boolean => {
    const route = routes.find(x => compareUrl(x.url, url) || matchPath(url, {
        path: x.url,
        exact: true,
        strict: false,
        ...props
    }));
    return !!route?.allowGuest;
}

export const isAnonymousAllowedUrl = (url: string, props = {}): boolean => {
    const route = routes.find(x => compareUrl(x.url, url) || matchPath(url, {
        path: x.url,
        exact: true,
        strict: false,
        ...props
    }));
    return !!route?.allowAnonymous;
}

export const getRouteConfig = (url: string, props = {})  => {
    const route = routes.find(x => compareUrl(x.url, url) || matchPath(url, {
        path: x.url,
        exact: true,
        strict: false,
        ...props
    }));
    return route;
}

export const registerRoute = (routeName: string): void => {
    if (!routeName && typeof routeName !== 'string') {
        throw new Error('routeName params (String) is required');
    }
    if (!!routes.find(route => route.name === `custom-${routeName}`)) {
        return;
    }
    routes.push({ name: `custom-${routeName}`, url: `/custom/${routeName}` });
};

export const addArrayQueryParam = (name: string, array: string[]): string => array.reduce((acc, curr) => createUrl(acc, `${name}[]=${curr}`), '');

export const setHashParameter = (hash: string): void => {
    window.location.hash = hash
};

export const getHashParameters = () => window.location.hash.substr(1).split('&');

export const removeAllHashParameters = () => history.pushState("", document.title, window.location.pathname + window.location.search); // eslint-disable-line

export const hasHashParameter = (hash: string): boolean => {
    const currentlyDisplayedHashParameters = getHashParameters();
    return !!currentlyDisplayedHashParameters.includes(hash);
}

export const getRouteParams = (paramName: string) => ({ match }: { match: { params: { [key: string]: string | null | undefined } } }): string | null | undefined => {
    if (!match || typeof match.params !== 'object') {
        return null;
    }

    return match.params[paramName] ? decodeURIComponent(match.params[paramName] || '') : undefined;
};

export const getParams = () => (pathName: string) : string | null | undefined => {
    if (!pathName) {
        return null;
    }

    return pathName.split('/')[
        pathName.split('/').length - 1
    ];
};

export const getQueryStringParameter = (paramName: string) => (qs: string): string => {
    let name = paramName;

    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(qs);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const isCallUrl = (): boolean => window.location.pathname.indexOf('room') !== -1;
