import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, message } from 'antd';
import { injectIntl } from 'react-intl';
import { utils } from '@amplement/backend-connector';

import { STEPS } from 'components/Room/Requierements';
import { PermissionDenied, RequestPermission } from 'components/Room/Requierements/Screens';
import DeviceManager from 'components/Room/Helpers/DeviceManager';
import DeviceSettings from 'components/Room/Settings/Devices';
import Devices from 'components/Listeners/Devices/container';
import UnsupportedBrowserComponent from 'components/LeftMenu/Rooms/UnsupportedBrowser';

import { isNativeScreenSharingSupported } from 'services/screenSharing';
import { getAvailableDevices, getRoomSettings } from 'selectors/roomSettings';
import { setDeviceSettings } from 'actions/roomSettings';
import { isWebRtcCompatible } from 'utils/webRTC';

const StreamManager = utils.streamManager;

const buttonProps = { size: 'md', iconName: '', style: { justifySelf: 'end' } };

export const handleGetStream = async (constraints) => {
    const hasVideoConstraint = constraints.video || constraints.video === '';
    const stream = (constraints.audio || hasVideoConstraint) && await DeviceManager.getStream(constraints);

    if (stream) await StreamManager.setStreamTrackEnabled(stream, 'video', false, true);
    if (stream) await StreamManager.setStreamTrackEnabled(stream, 'audio', false, true);
}

const CallConfiguration = ({
    updateDeviceSettings,
    settings,
    devices,
    intl,
}) => {
    const [step, setStep] = useState(STEPS.REQUEST);

    const checkPermissions = useCallback(async () => {
        try {
            const { state: audioPerms } = await DeviceManager.getPermissionStatus('microphone');
            const { state: videoPerms } = await DeviceManager.getPermissionStatus('camera');
            if (audioPerms === 'granted' && videoPerms === 'granted') {
                setStep(STEPS.CONFIGURE);
            }
            if (audioPerms === 'denied' || videoPerms === 'denied') {
                setStep(STEPS.PERMISSION_DENIED);
            }
        } catch (e) {
            setStep(STEPS.REQUEST);
        }
    }, []);

    const handleRequest = useCallback(async () => {
        const constraints = {
            audio: devices?.some(d => d.kind === 'audioinput') ? settings?.audioinput : false,
            video: devices?.some(d => d.kind === 'videoinput') ? settings?.videoinput : false
        };

        try {
            await handleGetStream(constraints);
            setStep(STEPS.CONFIGURE);
        } catch (e) {
            try {
                await handleGetStream({ audio: settings.audioinput });
                setStep(STEPS.CONFIGURE);
            } catch (err) {
                setStep(STEPS.REQUEST);
            }
        }
    }, [devices, settings, handleGetStream, checkPermissions]);

    const handleSubmit = useCallback((newSettings) => {
        updateDeviceSettings(newSettings);
        message.success({ content: intl.formatMessage({ id: 'global.button.saveSuccess' }) });
    }, [updateDeviceSettings])
    const handleReload = useCallback(() => setStep(STEPS.REQUEST), []);

    useEffect(() => {
        if (!isWebRtcCompatible()) {
            setStep(STEPS.WEBRTC_NOT_SUPPORTED);
        } else if (!isNativeScreenSharingSupported) {
            setStep(STEPS.SCREEN_SHARING_NOT_SUPPORTED);
        }
    }, []);

    useEffect(() => {
        checkPermissions();
    }, [checkPermissions]);

    if (step === STEPS.WEBRTC_NOT_SUPPORTED) {
        return <UnsupportedBrowserComponent />;
    }

    if (step === STEPS.REQUEST) {
        return (
            <Card>
                <RequestPermission
                    audio
                    video
                    onRequest={handleRequest}
                />
            </Card>
        );
    }

    if (step === STEPS.PERMISSION_DENIED) {
        return <PermissionDenied onReload={handleReload} />;
    }

    return (
        <Card>
            <Devices />
            <DeviceSettings
                footerButtonProps={buttonProps}
                showSkip={false}
                onSubmit={handleSubmit}
            />
        </Card>
    );
};

CallConfiguration.propTypes = {
    intl: PropTypes.any,
    settings: PropTypes.object,
    devices: PropTypes.array,
    updateDeviceSettings: PropTypes.func,
};

CallConfiguration.defaultProps = {
    intl: {},
    settings: {},
    devices: [],
    updateDeviceSettings: () => {},
};

const mapStateToProps = (state) => ({
    settings: getRoomSettings(state),
    devices: getAvailableDevices(state),
});

const mapDispatchToProps = dispatch => ({
    updateDeviceSettings: (settings) => dispatch(setDeviceSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CallConfiguration));
