import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Screens from 'screens/container';
import CustomIntlProvider from 'components/CustomIntlProvider/container';
import { getCompanyLanguageSelector } from 'selectors/company';
import { requestRootInit } from 'actions/init';
import 'utils/firebase';
import Toast from 'Toast';
import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';
import ThemeProvider from 'components/ThemeProvider/ThemeProvider';
import { initiator as backendConnectorInitiator } from 'utils/backendConnector';
import { i18n } from '@amplement/backend-connector';
import config from 'config';
import { getUserSettingsSelector, getIsGuestSelector, getCurrentUserIdSelector } from 'selectors/user';
import CustomTourProvider from 'components/Tour/CustomTourProvider';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import { SessionProvider } from 'contexts/sessionContext';
import SoundMeterProvider from 'contexts/SoundMeterContext';

const queryClient = new QueryClient();

backendConnectorInitiator();

const toggleButtonProps = { style: { margin: '40px' } };

const BaseApp = (props) => {
    const {
        initialize,
        locale
    } = props;

    useWhyDidYouUpdate('component:baseApp', props);

    useEffect(initialize, []);

    useEffect(() => { i18n.setI18nConfig(locale); }, [locale]);

    return (
        <SessionProvider>
            <ThemeProvider>
                <CustomIntlProvider>
                    <CustomTourProvider>
                        <SoundMeterProvider>
                            <QueryClientProvider client={queryClient}>
                                <Screens />
                                <ReactQueryDevtools
                                    position="bottom-right"
                                    toggleButtonProps={toggleButtonProps}
                                />
                            </QueryClientProvider>
                        </SoundMeterProvider>
                    </CustomTourProvider>
                </CustomIntlProvider>
                <Toast />
            </ThemeProvider>
        </SessionProvider>
    );
}

BaseApp.propTypes = {
    initialize: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    locale: getUserSettingsSelector(state)?.language ||
        (
            // https://github.com/amplement/web-client/issues/1393
            // checked with marco, if user is guest, get the browser language
            !getCurrentUserIdSelector(state) || getIsGuestSelector(state) ?
                (navigator.language || navigator.userLanguage)?.slice(0, 2)?.toLowerCase() :
                getCompanyLanguageSelector(state)
        ) ||
        config.defaultLanguage
});

const mapDispatchToProps = dispatch => ({
    initialize: () => dispatch(requestRootInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseApp);
