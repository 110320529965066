import { useQuery, UseQueryResult } from "react-query"

import { apis } from '@amplement/backend-connector';

export type CallForward = {
    trigger: string;
    type: string;
    to: string;
    context: string;
    delay?: number;
}

type UseCallForwardsProps = {
    _sipAccount: string;
    trigger?: string;
}

export type UseCallForwardsResult = {
    data?: CallForward[];
};

const useCallForwards = ({ _sipAccount, trigger }: UseCallForwardsProps): UseQueryResult => {
    const result = useQuery(
        ['callForward',  { _sipAccount, trigger } ],
        async () => {
            const response: UseCallForwardsResult = await apis.callForward.$getAllForwardsSettings(_sipAccount, trigger);

            return response?.data || [];
        },
        {
            enabled: !!_sipAccount,
            refetchOnWindowFocus: false,
            staleTime: 60000
        }
    )

    return result;
}

export default useCallForwards;
