/* eslint-disable */
import { normalize } from 'normalizr';
import { push } from 'redux-first-history';
import storage from 'services/storage/localStorage';
import { getPaginationInfos } from 'utils/state/api';
import { isGuestAllowedUrl, isAnonymousAllowedUrl } from 'utils/url';
import { setContentError } from 'actions/session';
import { BUTTON_TYPE } from 'const/fullscreenMessage';
import { HTTP_ERRORS_TYPES } from 'sagas/shared/errors';

export default ({ dispatch, getState }) => next => (action) => {
    const {
        types,
        callAPI,
        storageName,
        shouldCallAPI = () => true,
        payload = {},
        schemas,
        params,
        hash: hashFromAction,
        isFlushBeforeFetch: isFlush,
        resolver = () => { }
    } = action;
    let hash = hashFromAction;
    const state = getState();

    if (params && !hash) {

        hash = Object.keys(params).map(key => `${key}-${params[key]}`).join('_');

    }

    if (!types) {
        // Normal action: pass it on
        return next(action);
    }

    if (
        !types instanceof Array ||
        types.length !== 3 || !types.every(type => typeof type === 'string')
    ) {
        throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
        throw new Error('Expected fetch to be a function.');
    }

    const [requestType, successType, failureType] = types;

    if (!shouldCallAPI(state)) {

        return next(action);

    }

    dispatch(Object.assign({}, payload, {
        type: requestType,
        payload,
        params,
        hash,
        isFlush
    }));
    const resolvedState = resolver(state);

    return callAPI(resolvedState).then(
        (result) => {

            const response = { ...result };
            let data = response.data;

            if (data && data.data) {


                data = data.data;

            }

            if (schemas && typeof data === "object") {

                response.data = normalize(data, schemas);

            } else {

                response.data = data;

            }

            if (storageName) {

                storage.setItems(storageName, response);

            }

            const pagination = getPaginationInfos(response);

            dispatch(Object.assign({}, response, {
                pagination,
                response,
                payload,
                origin: 'api',
                type: successType,
                params,
                hash
            }));

            return response;

        },
        error => {
            const { status, data, request } = error || {};
            const { pathname } = window.location;
            const isNetworkError = !data && request?.__sentry_xhr__?.status_code === 0;

            if (status === 401 && !isGuestAllowedUrl(pathname) && !isAnonymousAllowedUrl(pathname)) {
                dispatch(setContentError(HTTP_ERRORS_TYPES[401].key, HTTP_ERRORS_TYPES[401].settings));
            }

            dispatch(Object.assign({}, {
                payload,
                status,
                error: isNetworkError ? 'Server not responding' : data,
                origin: 'api',
                type: failureType,
                params,
                hash
            }));

            return error;
        }
    );
}
