import React, { Suspense, lazy, memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { EmojiConvertor } from 'emoji-js';

const emoji = new EmojiConvertor();

const Markdown = lazy(() => import('components/Shared/Common/Markdown'));

const ContentTemplate = ({ content, onLoad }) => {
    if (content) {
        return (
            <Suspense fallback={content}>
                <Markdown content={emoji.replace_colons(content)} onLoad={onLoad} />
            </Suspense>
        );
    }
    return undefined;
};

ContentTemplate.propTypes = {
    content: PropTypes.string
};

ContentTemplate.defaultProps = {
    content: null
};

export default memo(injectIntl(ContentTemplate));

