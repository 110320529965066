import { apiRequest } from 'utils/network/request';
import { addArrayQueryParam } from 'utils/url';
import Firebase from 'utils/firebase';
import { apiClient as request } from '@amplement/backend-connector';
import { getToken } from 'services/token';

export const $patchUserEmail = (email, password) =>
    request.apiClient({
        url: '/settings/email',
        method: 'GET',
        body: { email, password }
    });


export const $getMe = () => 
    request.getAxiosProvider()({
        method: 'get',
        url: '/users/me',
        headers: { 
            Authorization: getToken(),
            'Content-Type': 'application/json; charset=utf-8' 
        },
    });

export const $requestGetUser = _user => 
    request.apiClient({
        url: `/users/${_user}`,
        method: 'GET',
    });

export const $requestGetUsers = _users => 
    request.apiClient({
        url: `/v5/users${addArrayQueryParam('ids', _users)}`,
        method: 'GET',
    });

export const $requestPatchUser = (_user, data) => {
    Firebase.logAnalyticsEvent('user_patch');
    return request.apiClient({
        url: `/v5/users/${_user}`,
        method: 'PATCH',
        body: data
    });
}

export const $searchUsers = (query, contactStatus, isInitiator, limit, page, sip) => {
    Firebase.logAnalyticsEvent('user_search');
    return apiRequest({
        url: '/v5/users/search',
        method: 'GET',
        params: {
            q: query, contactStatus, limit, page, isInitiator, sip
        },
        schemaName: 'users'
    });
}

export const $searchUsersV2 = (query, contactStatus, isInitiator, limit, page, sip) => {
    Firebase.logAnalyticsEvent('user_search');
    return request.getAxiosProvider()({
        url: '/v5/users/search',
        method: 'GET',
        params: {
            q: query, contactStatus, limit, page, isInitiator, sip
        }
    });
}

// CRUD CONTACTS
export const $getContactsV2 = (status, isInitiator, limit, page, sip) =>
    request.getAxiosProvider()({
        url: `/v5/contacts/`,
        method: 'GET',
        params: {
            status, limit, page, isInitiator, sip
        }
    });

export const $getContacts = (status, isInitiator, limit, page, sip) =>
    apiRequest({
        url: `/v5/contacts/`,
        method: 'GET',
        params: {
            status, limit, page, isInitiator, sip
        },
        schemaName: 'users'
    });

export const $getContact = _user =>
    request.apiClient({
        method: 'GET',
        url: `/v5/contacts/${_user}`
    });

export const $postContact = _user => {
    Firebase.logAnalyticsEvent('contact_post');
    return request.apiClient({
        url: '/v5/contacts/',
        method: 'POST',
        body: { _user }
    });
}

export const $deleteContact = _user =>{
    Firebase.logAnalyticsEvent('contact_delete');
    return request.apiClient({
        url: `/v5/contacts/${_user}`, 
        method: 'DELETE'
    });
}

export const $patchContact = (_user, data) =>{
    Firebase.logAnalyticsEvent('contact_patch');
    return request.apiClient({
        url: `/v5/contacts/${_user}`, 
        method: 'PATCH',
        body: data
    });
}

export const $postRelationsBulk = (emails) =>
    request.apiClient({
        url: `/v5/contacts/bulk`, 
        method: 'POST',
        body: emails
    });
