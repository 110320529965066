import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const JoinTemplate = ({ intl, onLoad }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (onLoad && !isLoaded) {
            onLoad();
            setIsLoaded(true);
        }
    }, [onLoad]);

    return intl.formatMessage({
        id: 'feedItem.joinStatus',
        defaultMessage: 'a rejoint le groupe'
    });
};

JoinTemplate.propTypes = {
    onLoad: PropTypes.func,
    intl: PropTypes.object.isRequired
};

JoinTemplate.defaultProps = {
    onLoad: undefined
};

export default memo(injectIntl(JoinTemplate));
