import React, { useMemo } from 'react';
import { Tooltip, Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { CallForward } from 'hooks/useCallForwards';

import { FORWARD_TRIGGER_TYPES, FORWARD_TYPES } from 'const/call';

import { colors } from 'common/themes/Colors';

const getTranslations = (intl) => ({
    busy: intl.formatMessage({ id: 'call.forward.title.busy' }),
    unavailable: intl.formatMessage({ id: 'call.forward.title.unavailable' }),
    unconditional: intl.formatMessage({ id: 'call.forward.title.unconditional' }),
    unreachable: intl.formatMessage({ id: 'call.forward.title.unreachable' }),
    unconditionalTooltip: intl.formatMessage({ id: 'call.forward.description.unconditional' }),
});

const { Paragraph, Text } = Typography;

const StyledParagraph = styled(Paragraph)`
    display: flex;
    align-items: center;
    margin-bottom: 0;
`;

const StyledText = styled(Text)`
    margin-left: 10px;
`;

const StyledContainer = styled.div`
    width: 50%;
    display: flex;
    margin-top: 8px;
    & > div {
        margin-bottom: 0;
    }
`;

type ForwardViewProps = {
    isLoading: boolean;
    error: string | null;
    isError: boolean;
    data: CallForward[];
} & WrappedComponentProps;

export const ForwardView = ({
    isLoading,
    error,
    isError,
    data,
    intl,
}: ForwardViewProps) : JSX.Element[] | JSX.Element => {
    const translations = useMemo(() => getTranslations(intl), [intl]);
    const filteredData = useMemo(() => data?.filter(forward => forward?.type !== FORWARD_TYPES[4]), [data]);

    if (isError) {
        return <div>{error}</div>;
    }

    if (isLoading) {
        return <LoadingOutlined />;
    }

    return filteredData?.map((forward: CallForward) => (
        <StyledContainer key={forward?.trigger}>
            <StyledParagraph>
                <CheckCircleTwoTone twoToneColor={colors.success} />
                {forward?.trigger === FORWARD_TRIGGER_TYPES[2] ? (
                    <Tooltip title={translations[`${forward?.trigger}Tooltip`]}>
                        <StyledText strong type="danger">
                            {translations[forward?.trigger]}
                        </StyledText>
                    </Tooltip>
                ) : (
                    <StyledText>
                        {translations[forward?.trigger]}
                    </StyledText>
                )}
            </StyledParagraph>
        </StyledContainer>
    ));
}

export default injectIntl(ForwardView);
