import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'antd';

import CallTabs from 'components/Shared/Drawers/Sip/CallTabs';

import { StyledPageLayout } from 'components/Shared/style';
import CategoryTitle from 'components/Shared/Layout/CategoryTitle';
import Icon from 'components/Shared/Common/Icon';
import Link from 'components/Shared/Common/Link';

import { getUrl } from 'utils/url';

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: -20px;
`;

const Screen = ({ intl, history }) => (
    <StyledPageLayout className="ui-contact">
        <Link href={getUrl('dashboard')}>
            <StyledButton type="link">
                <Icon size="xl" iconName="ChevronLeft" />
                {intl.formatMessage({ id: 'menuLeft.navigation.goBackToDashboard' })}
            </StyledButton>
        </Link>
        <CategoryTitle
            label={intl.formatMessage({ id: 'menuLeft.navigation.phone' })}
            icon={<Icon size="xl" iconName="Phone" />}
        />
        <CallTabs history={history} />
    </StyledPageLayout>
);

Screen.propTypes = {
    intl: PropTypes.any.isRequired,
};

Screen.defaultProps = {
};

export default memo(injectIntl(Screen));
