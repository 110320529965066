import React, { useMemo } from 'react';
import { Typography, Badge, Button, Tooltip, Grid } from 'antd';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectors, actions } from '@amplement/backend-connector';
import { EllipsisOutlined } from '@ant-design/icons';

import Icon from 'components/Shared/Common/Icon';
import Row from 'components/CallBuilder/Row/Row';
import { RoomLabel, getIconName, getRoomLabelId } from 'components/Shared/Notification/Room';
import Link from 'components/Shared/Common/Link';
import StyledCustomButton from 'components/CallBuilder/DefaultResults/style';
import CustomButton from 'components/Shared/Common/Button';

import { colors } from 'common/themes/Colors';

import { StyledCard, StyledCol } from 'screens/Dashboard/styles';

import { NOTIFICATION_CATEGORIES, NOTIFICATION_ROOM_TYPES } from 'const/notification';

import useNotifications, { NotificationData } from 'hooks/useNotifications';
import { getCurrentClientIdSelector, getCurrentUserIdSelector } from 'selectors/user';
import enrichMembers from 'services/members';

import { getUrl } from 'utils/url';

const { Title } = Typography;

const { useBreakpoint } = Grid;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h4 {
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;

        @media (min-width: 1480px) and (max-width: 1600px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
        }

        @media (min-width: 1360px) and (max-width: 1480px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 75px;
        }

        @media (min-width: 1200px) and (max-width: 1360px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50px;
        }
    }
`;

const StyledCardContainer = styled(StyledCard)`
    .ant-card-body {
        padding: 0;
    }
`;

const StyledCallButton = styled(StyledCustomButton)`
    margin-right: 10px;
`;

const StyledRow = styled(Row)`
    padding: 10px;
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledMarkAsSeenButton = styled(StyledCustomButton)`
    margin-right: 5px;
`;

const StyledActions = styled.div`
    display: flex;
    align-items: center;
`;

type MissedCallNotificationPropsType = {
    notification: NotificationData;
    _myUser: string;
    _myClient: string;
} & WrappedComponentProps;

export const MissedCallNotification = ({
    notification: {
        payload: {
            name,
            members = [],
            _sourceFeed,
            isPersistent,
            isMulticast,
            id: _room,
            caller: {
                isMyUser,
            } = {},
        },
        type,
        createdAt: date,
    },
    intl,
    _myUser,
    _myClient,
}: MissedCallNotificationPropsType): JSX.Element => {
    const status = useMemo(() => (
        <>
            <Icon iconName={getIconName(isMyUser, type, false, members)} />
            {intl.formatMessage({ id: getRoomLabelId(isMyUser, type, members) })}
        </>
    ), [isMyUser, type, members, intl]);

    const isMissed = useMemo(() => type === NOTIFICATION_ROOM_TYPES.MISSED, [type]);

    const enrichedMembers = useMemo(() => enrichMembers(_room, _myUser, _myClient)(members), [members]);

    return (
        <StyledRow
            label={(
                <RoomLabel
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    isPersistent={isPersistent}
                    members={enrichedMembers}
                    name={name}
                    className="s-recent-call-name"
                    isMulticast={isMulticast}
                    _sourceFeed={_sourceFeed}
                />
            )}
            members={enrichedMembers}
            date={date}
            status={status}
            isMissed={isMissed}
            hidePlusButton
            onAddParticipant={() => {}}
            onRemoveParticipant={() => {}}
            hasParticipant={false}
        >
            <StyledCallButton
                iconName="Phone"
                _room={_room}
                _feed={_sourceFeed}
                members={enrichedMembers}
                isPersistent={isPersistent}
            >
                <p>{intl.formatMessage({ id: 'call.call' })}</p>
            </StyledCallButton>
        </StyledRow>
    )
}

type MissedCallCardPropsType = {
    max?: number;
    callNotificationCount: number;
    markNotificationAsRead: () => void;
} & WrappedComponentProps;

export const MissedCall = ({
    intl,
    max = 50,
    callNotificationCount,
    markNotificationAsRead
}: MissedCallCardPropsType): JSX.Element | null => {
    const _myUser = useSelector(getCurrentUserIdSelector);
    const _myClient = useSelector(getCurrentClientIdSelector);
    const { data = [] } = useNotifications({
        category: NOTIFICATION_CATEGORIES.ROOM,
        type: NOTIFICATION_ROOM_TYPES.MISSED,
        limit: max,
        page: 1,
        ignored: undefined,
        read: false,
        notificationCount: callNotificationCount,
    });
    const { lg, xl, xxl } = useBreakpoint();

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="ExclamationCircle" />
            <StyledTitle>
                <div className="label">
                    <Tooltip title={intl.formatMessage({ id: 'call.callhistory.status.missedIncoming' })}>
                        <Title level={4}>{intl.formatMessage({ id: 'call.callhistory.status.missedIncoming' })}</Title>
                    </Tooltip>
                    <Badge overflowCount={999} count={callNotificationCount} />
                </div>
                <StyledActions>
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({ id: 'global.button.markAsSeenDescription' })}
                    >
                        <StyledMarkAsSeenButton onClick={markNotificationAsRead}>
                            <CustomButton iconName="CheckCircle">
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                {isLargeScreen && <p>{intl.formatMessage({ id: 'global.button.markAsSeen' })}</p>}
                            </CustomButton>
                        </StyledMarkAsSeenButton>
                    </Tooltip>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Link
                        href={getUrl('call')}
                        className="text"
                    >
                        <Tooltip
                            placement="top"
                            title={intl.formatMessage({ id: 'global.button.showMore' })}
                        >
                            <StyledButton shape="circle">
                                <EllipsisOutlined />
                            </StyledButton>
                        </Tooltip>
                    </Link>
                </StyledActions>
            </StyledTitle>
        </StyledContainer>
    ), [intl, callNotificationCount, isLargeScreen]);

    if (!data || !data.length) {
        return null;
    }

    return (
        <StyledCol>
            <StyledCardContainer
                maxHeight={500}
                title={cardTitle}
            >
                {data.map((notification: NotificationData) => (
                    <MissedCallNotification
                        key={notification.id}
                        _myUser={_myUser}
                        _myClient={_myClient}
                        notification={notification}
                        intl={intl}
                    />
                ))}
            </StyledCardContainer>
        </StyledCol>
    )
};

MissedCall.propTypes = {
    intl: PropTypes.object.isRequired,
    callNotificationCount: PropTypes.number.isRequired,
    max: PropTypes.number,
};

MissedCall.defaultProps = {
    max: 50,
};

const mapStateToProps = state => ({
    callNotificationCount: selectors.notifications.getUnreadNotificationCountByCategorySelector(state, NOTIFICATION_CATEGORIES.ROOM) || 0,
});

const mapDispatchToProps = dispatch => ({
    markNotificationAsRead: () => dispatch(actions.notifications.requestMarkNotificationsAsRead(NOTIFICATION_CATEGORIES.ROOM, NOTIFICATION_ROOM_TYPES.MISSED)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MissedCall as React.FC<MissedCallCardPropsType>));
