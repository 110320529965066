import  { initializeApp } from 'firebase/app';
import { 
    getAnalytics, 
    setUserId, 
    setCurrentScreen, 
    setUserProperties, 
    logEvent,
    isSupported
} from "firebase/analytics";
import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc
} from 'firebase/firestore';
import { getToken } from 'services/token';
import { utils } from '@amplement/backend-connector';
import storage from 'services/storage/localStorage';

import config from 'config';

import Logger from 'services/debug/logger';

const logger = new Logger('utils:analytics');

const getAnalyticsIfSupported = () => new Promise((resolve, reject) => {
    isSupported().then((isAvailable) => {
        if (isAvailable) {
            resolve();
        } else {
            reject();
        }
    });
});

export const ON_BOARDING_MAX_VIEWS = 3;

class Firebase {

    #app = () => {};

    #analytics = () => {};

    #firestore = () => {};

    isAnalyticsSupported;
    
    prevScreen;

    constructor() {
        try {
            this.#app = initializeApp(config.firebaseConfig);
            getAnalyticsIfSupported().then(() => {
                // this.isAnalyticsSupported = true;
                this.#analytics = getAnalytics(this.#app);
                this.#firestore = getFirestore(this.#app);
                // should be automatic, but for web, it's not ! hack the version, need to take some time to do that
                this.setUserProperty('app_version', process.env.PKG_VERSION);
                this.setUserProperty('bundleId', storage.get('getBundleId') || 'web');
            }).catch(() => {
                this.isAnalyticsSupported = false;
            });
        } catch (e) {
            // 😰
            utils.errorHandler.captureException(e, 'analytics:constructor');
        }
    }

    setUserId =  userId => {
        logger.log('setUserId', userId);
        try {
            this.#analytics = getAnalytics(this.#app);
            return setUserId(this.#analytics, userId);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setUserId');
            return null;
        }
    }

    setUserProperty = (tag, value) => {
        logger.log('setUserProperty', tag, value);
        if (!tag || !value) return null;
        return this.setUserProperties({[tag]: value});
    }

    setUserProperties = (properties) => {
        logger.log('setUserProperties', {...properties});
        if (!properties || !this.isAnalyticsSupported) return null;
        try {
            this.#analytics = getAnalytics(this.#app);
            return setUserProperties(this.#analytics, properties);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setUserProperties');
            return null;
        }
    };

    setCurrentScreen = screen => {
        if (!screen || this.prevScreen === screen || !this.isAnalyticsSupported) return null;
        logger.log('setCurrentScreen', screen)
        this.prevScreen = screen;
        try {
            this.#analytics = getAnalytics(this.#app);
            return setCurrentScreen(this.#analytics, screen);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:setCurrentScreen');
            return null;
        }
    }

    logAnalyticsEvent = (tag, body = {}) => {
        const data = { env: config.env, ...body };
        logger.log('logEvent', tag, {...data});
        if (!this.isAnalyticsSupported) return null;
        try {
            this.#analytics = getAnalytics(this.#app);
            return logEvent(this.#analytics, tag, data);
        } catch (e) {
            utils.errorHandler.captureException(e, 'analytics:logEvent');
            return null;
        }
    };

    createOnBoarding = async (campaignId, settings, markedAsSeen) => {
        try {
            const response = await fetch(`${config.apiServerUrl}/v5/users/settings`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: getToken()
                },
                body: JSON.stringify({
                    settings: {
                        ...settings,
                        onBoarding: {
                            ...settings?.onBoarding,
                            campaigns: {
                                ...settings?.onBoarding?.campaigns,
                                [campaignId]: {
                                    ...settings?.onBoarding?.campaigns?.[campaignId],
                                    id: campaignId,
                                    isViewed: markedAsSeen,
                                    viewsCount: 1
                                }
                            }
                        }
                    }
                }),
            });

            return response;
        } catch (error) {
            utils.errorHandler.captureException(error, 'TabEventListener:requestRoomLeave');
            return error;
        }
    };

    updateOnBoarding = async (campaignId, prevSettings, markedAsSeen) => {
        try {
            const response = await fetch(`${config.apiServerUrl}/v5/users/settings`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: getToken()
                },
                body: JSON.stringify({
                    settings: {
                        ...prevSettings,
                        onBoarding: {
                            ...prevSettings.onBoarding,
                            campaigns: {
                                ...prevSettings.onBoarding.campaigns,
                                [campaignId]: {
                                    ...prevSettings.onBoarding.campaigns[campaignId],
                                    isViewed: !!markedAsSeen || prevSettings.onBoarding.campaigns[campaignId].viewsCount >= (ON_BOARDING_MAX_VIEWS - 1),
                                    viewsCount: prevSettings.onBoarding.campaigns[campaignId].viewsCount + 1,
                                }
                            }
                        }
                    }
                }),
            });

            return response;
        } catch (e) {
            utils.errorHandler.captureException(e, 'TabEventListener:requestRoomLeave');
            return null;
        }
    };

    findActiveCampaigns = async () => {
        try {
            const campaignsCollection = collection(this.#firestore, 'campaigns');
            const today = new Date();
            const activeCampaignsQuery = query(
                campaignsCollection,
                where('isActive', '==', true),
                where('endDate', '>', today)
            );
            const campaignsSnapshot = await getDocs(activeCampaignsQuery);
            const campaigns = campaignsSnapshot.docs.filter(dc => dc.data().startDate.toDate() < today);

            if (campaignsSnapshot.empty || !campaigns.length) {
                return null;
            }

            return campaigns.map(dc => dc.data());
        } catch (error) {
            utils.errorHandler.captureException(error, 'analytics:findActiveCampaigns');
            return error;
        }
    };

    getUserSettings = async userId => {
        try {
            const userDocRef = doc(this.#firestore, 'users', userId);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const { settings } = userDoc.data();
                return settings;
            }

            return null;
        } catch (error) {
            utils.errorHandler.captureException(error, 'analytics:getUserSettings');
            return error;
        }
    }
}

export default new Firebase();
